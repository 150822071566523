
import React from 'react'
import DOMPurify from 'dompurify';
import './AboutTheDeveloper.css'
const AboutTheDeveloper = ({ data }) => {
    const { aboutthedeveloper } = data;
    const sanitizedDescription = DOMPurify.sanitize(data?.aboutthedeveloper.para || '');
    return (


        <div
            className='container '
            style={{
                color : 'black'
            }}
        >
            <div
                className='aboutTheDeveloperImage'
            >

                <img src={aboutthedeveloper?.imageLink} />
            </div>


            <h3
                style={{
                    margin: "15px 0",
                   
                    textAlign: 'center'
                }}
            >
                {aboutthedeveloper?.heading}
            </h3>
            <h2
                className='aboutthedevelopermainheading'
                style={{
                    margin: "15px 0",
                    textAlign: 'center'
                }}
            
            >
                {
                    aboutthedeveloper?.mainHeading
                }
            </h2>
            <div
                style={{
                    lineHeight: "25px",
                }}
                dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
            />

        </div>
    )
}

export default AboutTheDeveloper