import React from 'react'
import { Link } from 'react-router-dom'


const Logo = ({data}) => {
  const logo = data?.logo
  return (
    <Link
     to = '/'
      style={{
        display: 'flex',
        justifyContent: "center",
        alignItems : "center"
      }}
    >
      <img
        alt ="Logo"
        
        style={{
          width: "150px"
          
        }}
        loading="lazy"
        src={logo} />
    </Link>
  )
}

export default Logo
