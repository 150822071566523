import axios from "axios";
import { backendUrl } from "./backendUrl";


const axiosInstance = axios.create({
  baseURL: backendUrl,
  headers: {},
  withCredentials: false,
});


export const apiCalling = async (values ,data) => {
  try {
    const { name, email, phone, message, reason } = values;
    console.log(
      "name, email, phone, message, reason",
      name,
      email,
      phone,
      message,
      reason
    );
    await axiosInstance.post("/", {
      name,
      email,
      phone,
      message,
      reason,
      source: data?.projectName,
    });
    return "success";
  } catch (e) {
    console.error("Error:", e);
    return "failure";
  }
};
