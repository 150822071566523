import React from 'react'
import './HighlightingCard.css'

import { Col } from 'antd'
const HighlightingCard = ({card ,data}) => {

  return (
      <Col
          className='highLightedCard'>
          <h2>
              {
                  card?.heading
              }
              
          </h2>
          <div>
              
        
          {
              card?.points?.map((point, key) => {
                  return (<div className='highlightedCardPoint'>
                      <div className='pointHighlight'></div>
                      <div className='pointItem'
                          dangerouslySetInnerHTML={{ __html: point }}
                      />
                      </div>)
              })
              }
          </div>
          
    </Col>
  )
}

export default HighlightingCard