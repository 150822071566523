import React, { Suspense } from 'react';

const Logo = React.lazy(() => import('../Logo/Logo'));

const Loader = ({data}) => {
    return (
        <div
            style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100vw",
                height: "100vh",
                backgroundColor: "white",
                display: 'flex',
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Suspense fallback={<div></div>}>
                <React.Suspense fallback={<div></div>}>
                    <Logo data ={data} />
                </React.Suspense>
            </Suspense>
        </div>
    );
}

export default Loader;