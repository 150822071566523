import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const GATracking = ({ trackingId }) => {


    return (
        <>
         
        </>
    );
};

export default GATracking;
