import React, { useState } from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from 'react-icons/md';
import image from '../../../assets/whatsapp.png';
import { Modal } from 'antd';
import PopupDynamic from '../popup/PopupDynamic';


const CallingFooter = ({ dialCode, setIsThankYouPage,data }) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const { cardData, details } = data;

    const handleWhatsAppClick = () => {
        const recipientNumber = details?.whatsappNumber;
        const whatsappMessage = details?.whatsappMessage;
        const whatsappUrl = `https://wa.me/${recipientNumber}?text=${encodeURIComponent(whatsappMessage)}`;
        window.open(whatsappUrl, '_blank');
    };

    const showModal = () => {
        setIsModalVisible(true)
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {

        setIsModalVisible(false);

    };

    return (

        <div style={{

            display: 'flex',

            justifyContent: 'space-around',

            background: 'rgba(2,43,45,1)', 

            color: 'white',

            position: 'sticky',

            bottom: '0',

            zIndex: '34232342323423422342',

            padding: '10px 0'

        }}>

            <div style={{

                textAlign: 'center',

                display: 'flex',

                justifyContent: 'center',

                alignItems: 'center'

            }}

                onClick={() => { showModal() }}

            >

                <MdEmail size={22} />

                <div style={{ marginLeft: '5px' }}>

                    Enquiry

                </div>

            </div>

            <a

                href={`tel:${details?.phoneNumber}`}





                style={{

                    textDecoration: 'none',

                    textAlign: 'center',

                    display: 'flex',

                    justifyContent: 'center',

                    alignItems: 'center',

                    borderLeft: '1px solid white',

                    borderRight: '1px solid white',

                    padding: '0 20px',

                    color: "white"

                }}>



                <FaPhoneAlt size={22} />

                <div style={{ marginLeft: '5px' }}>

                    Call

                </div>

            </a>

            <div style={{

                textAlign: 'center',

                display: 'flex',

                justifyContent: 'center',

                alignItems: 'center'



            }}



                onClick={handleWhatsAppClick}>

                <img

                    src={image}

                    alt="WhatsApp"

                    style={{

                        width: '22px',

                        marginRight: '5px'

                    }}

                    loading='lazy'

                />

                <div



                >

                    WhatsApp

                </div>

            </div>



            <Modal

                footer={false}

                visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>

                <PopupDynamic
                    data={data}


                    setIsThankYouPage={setIsThankYouPage}

                    heading={cardData?.popupData?.heading}

                    button={cardData?.popupData?.button}

                    dialCode={dialCode}

                />

            </Modal>

        </div>

    );

}



export default CallingFooter;
