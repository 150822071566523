import React from 'react'
import Logo from '../Logo/Logo'
import './PopupForm.css'
import EntryForm from '../EntryForm/EntryForm'

const PopupForm = ({ heading, setIsThankYouPage, dialCode, data }) => {

    
    const { frontHighLightedCard } = data;
    
  return (
      <div className='popupForm'>
          <div  className='popupLogo'>
              
              <Logo data={data} />
          </div>
          <div className='textHeading'>
              {heading}
          </div>
          <div className='dataHeading'>
              {frontHighLightedCard?.getInTouch?.popupHeading}
          </div>
          
          <div>
              <div className='getInTouchFormHeading'>
                  <img src={frontHighLightedCard?.getInTouch?.heading?.imageLink} />
                  {frontHighLightedCard?.getInTouch?.heading?.label}
              </div>
          </div>
        

          <EntryForm
              setIsThankYouPage={setIsThankYouPage}
              dialCode={dialCode}
              heading={heading}
              data ={data}
          />
    </div>
  )
}

export default PopupForm