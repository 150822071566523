import React from 'react';
import DOMPurify from 'dompurify';
import './FooterSection.css';
const FooterSection = ({data}) => {
  const { footer } = data;
  const currentURL = window.location.href;
  const handleNavigate = () => {
    window.open(`${currentURL}/disclaimer`, "_blank");
  };

  const sanitizedDisclaimer = DOMPurify.sanitize(footer?.disclimer || '');
  
 
  return (
    <div
      style={{
        backgroundColor: "transparent"
      }}
      className='footer container'
    >
      <h5
        style={{
          textAlign: "center",
          padding: "10px 0 "
        }}
      >
        {footer?.topLine}
      </h5>
      <h5
        style={{
          textAlign: "center",
          padding: "10px 0 "
        }}
      >
        {footer?.bottomLine} 
        {" "}
        <a
          style={{
            color: 'blue',
            cursor: "pointer",
            textDecoration : "none"
          }}
          href={footer?.link}
        > {"("}{footer?.link}{")"}</a> 
      </h5>
      <p
        style={{
          fontSize : "12px"
        }}
        className= 'footerSectionDisclaimer'
      
      >
        <span>Disclaimer: <span dangerouslySetInnerHTML={{ __html: sanitizedDisclaimer }} /></span>
       
      </p>
      <p
        style={{
          textAlign: "center",
          padding: "5px 0 ",
          fontSize: "12px",
          cursor: "pointer",
          color: "blue"
        }}
        onClick={handleNavigate}
      >
        Disclaimer & Privacy Policy
      </p>
      <p
        style={{
          textAlign: "center",
          fontSize: "12px"
        }}

      >
        {footer?.footerAllRight}
      </p>
    </div>
  );
};

export default FooterSection;
