import React, { useState } from 'react'
import Logo from '../../components/Logo/Logo'

import './Navbar.css'
import { Modal } from 'antd'
import Hamburger from 'hamburger-react'
import PopupForm from '../../components/PopupForm/PopupForm'

const Navbar = ({ setIsThankYouPage, dialCode ,data}) => {
    const [modal, setModal] = useState(false);
    const [open, setOpen] = useState(false)
    const [sendingHeading,setSendingHeading] = useState(false)
    const handleClick = () => {
        setOpen(!open);
    };
  const NavItems = [
        {
            title: "OVERVIEW",
            url: "#overview",
            
        },
        {
            title: "BOOK A SITE VISIT",
            url: "#",
           
        },
        {
            title: "CONTACT US",
            url: "#",
        }
    ];


    return (
        <div>
            <div className='desktopNavbar '>
                <div className='desktopLogo center '>
                    <Logo data={data} />
                </div>
                <div className='desktopMenuItems center'>
                    {
                        NavItems?.map((item, index) => {
                            return <a
                                onClick={() => {
                                    if (item?.title != 'OVERVIEW') {
                                        setModal(true);
                                        setSendingHeading(item?.title)
                                    }
                                }}
                                href={item.url}
                                className='navItem' key={index}>
                                {item?.title}
                            </a>
                        })
                    }

                </div>

            </div>
            <div
                className='mobileNavbar' >
                <nav className="NavbarItems">
                    <Logo data={data} />
                   
                    <div  onClick={handleClick}>
                        <Hamburger className="Hamburger-Cross-Icons" toggled={open} toggle={setOpen} />
                    </div>
                    <ul className={open ? "MenuItems active" : "MenuItems"}>
                        {NavItems.map((Item, index) => {
                            return (
                                <li key={index}>
                                    <a
                                        onClick={() => {
                                            if (Item?.title != 'OVERVIEW') {
                                                setModal(true);
                                                setSendingHeading(Item?.title)
                                            }
                                        }}
                                        href={Item.url} className='nav-links'>
                                        
                                        {Item.title}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </nav>

            </div>
            <Modal
                open={modal}
                footer={false}
                onCancel={() =>setModal(false)}
            >
                <PopupForm
                    data = {data}
                    heading={sendingHeading}
                    setIsThankYouPage={setIsThankYouPage}
                    dialCode={dialCode}
                
                />
            </Modal>


        </div>
    )
}

export default Navbar