
import React, { useEffect, useState } from 'react';
import {  IoCheckmarkCircleSharp } from 'react-icons/io5';
import {  message as antdMessage } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Logo from '../Logo/Logo';
import ButtonBackground from '../Buttons/ButtonBackground/ButtonBackground';
import { apiCalling } from '../../../../config/apiCalling';
const PopupDynamic = ({
    heading, button,
    setIsThankYouPage,   dialCode,data
}) => {
    const [projectId, setProjectId] = useState()
 
    const [loading, setLoading] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');
    const [countryCode, setCountryCode] = useState('');
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 955);
        };

        window.addEventListener("resize", handleResize);

      
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    function toLowercaseJoined() {
        const sentence = heading + data?.projectId
        const words = sentence.split(' ');
       setProjectId(words.join('').toLowerCase())
    }
    useEffect(() => {
        toLowercaseJoined()
        
    },[])

  

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name) {
            antdMessage.error('Please enter your name.');
            return;
        }
        if (!email) {
            antdMessage.error('Please enter your email.');
            return;
        }
        if (!number) {
            antdMessage.error('Please enter your phone number.');
            return;
        }

        const formData = {
            name,
            email,
            phone: number,
            reason: heading,
            message
        };
        setLoading(true)
        const dataa = await apiCalling(formData,data)
        if (dataa === 'success') {
            setIsThankYouPage(true)
        } else {
            antdMessage.error("Something went wrong please try again later.")
        }
        setLoading(false)

    };
  
    return (
        <div
        >
            <div
                style={
                    !isSmallScreen ?
                        {
                            display: 'grid',
                            width: "100%",
                            gridTemplateColumns: "1fr 8fr 4fr",
                            height: '430px'
                        } : {
                            display: 'grid',
                            width: "100%",
                            gridTemplateColumns: "1fr 8fr ",
                            height: '470px'

                        }
                }

            >
                <div
                    style={{
                        backgroundColor: "rgba(249,249,249)",
                        padding: '5px',
                        display: 'flex',
                        flexDirection: "column"
                    }}
                >
                    <h3
                        style={{
                            textAlign: 'center',
                            padding: "20px 10px"
                        }}

                    >{data?.popup?.leftSide?.heading}</h3>


                    {
                        data?.popup?.leftSide?.iconsImages.map((item) => (
                            <div
                                key={item.id} // Ensure each child has a unique key
                                style={{
                                    margin: "20px 10px"
                                }}
                            >
                                <img
                                    src = {item?.imageLink}
                                    style={{
                                        fontSize: '2rem',
                                        display: 'flex',
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "30px",
                                        height : '30px',
                                        color: "rgba(2,43,45)",
                                        margin :'auto'
                                    }}
                                />
                                <h4
                                    style={{
                                        display: 'flex',
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%"
                                    }}
                                >{
                                        item?.label
                                }
                                </h4>
                            </div>
                        ))
                    }
                </div>
                <div
                    style={{
                        marginTop: "10px",
                        padding: '2px',
                    }}
                >

                    <Logo  data ={data} />
                    <div
                        style={{
                            textAlign: "center",

                            border: "2px solid rgba(2,43,45)",
                            borderRadius: "25px",
                            fontWeight: "bold",
                            width: "80%",
                            margin: "auto",
                            marginTop: "30px",
                            padding: "2px"

                        }}

                    >
                        {heading}
                    </div>
                    <div>
                        <form id = {projectId} onSubmit={handleSubmit}>
                            <div style={{ display: 'flex', borderBottom: '1px solid gray', margin: 'auto', width: '80%', color: 'gray', marginBottom: '20px' ,marginTop:'30px'}}>
                                {/* <label>Name</label> */}
                                <input
                                    placeholder='Name'

                                    style={{ border: "none", outline: 'none', paddingBottom: '8px', fontSize: "16px" }} value={name} onChange={(e) => setName(e.target.value)} required />
                            </div>
                            <div style={{ display: 'flex', borderBottom: '1px solid gray', margin: 'auto', width: '80%', color: 'gray', marginBottom: '16px' }}>
                                {/* <label>Email</label> */}
                                <input
                                    placeholder='Email'

                                    type="email" style={{ border: "none", outline: 'none', paddingBottom: '8px', fontSize: "16px" }} value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </div>
                            <div style={{ display: 'flex', borderBottom: '1px solid gray', margin: 'auto', width: '80%', color: 'gray', marginBottom: '16px' }}>
                                {/* <label>Email</label> */}
                                <input
                                   
                                    placeholder={data?.formData?.phonePlaceholder} 

                                    type="text" style={{ border : "none", width: "100%", letterSpacing: "-1.5px ",outline: 'none', paddingBottom: '8px', fontSize: "16px" }} value={number} onChange={(e) => setNumber(e.target.value)} required />
                            </div>
                         
                            <div style={{ display: 'flex', marginTop: '20px', margin: 'auto', width: '80%', color: 'gray', flexDirection: 'column', marginBottom: '10px', borderBottom: '1px solid GRAY' }}>
                               
                                <textarea
                                    placeholder= {data?.formData?.placeHolder}
                                    style={{ border: "none", outline: 'none', paddingBottom: '8px', fontSize: "16px" }} value={message} onChange={(e) => setMessage(e.target.value)} rows={data?.formData?.popup?.rows} />
                            </div>
                            <div style={{ width: '80%', margin: 'auto', marginTop: '10px' }}>
                                <ButtonBackground
                                    loading={loading}
                                    
                                    text={button} type="primary" htmlType="submit" />
                            </div>
                        </form>
                    </div>
                </div>

                {
                    !isSmallScreen &&
                    <div

                        style={{
                            backgroundColor: "rgba(2,43,45)",
                            color: 'white',
                            padding: "10px",
                            textAlign: "center",
                            paddingTop: "20px",

                        }}

                    >
                            <h2>{ data?.popup?.rightSide?.heading}</h2>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                marginTop: "50px"
                            }}

                        >

                                {
                                    data?.popup?.rightSide?.points?.map((item) => {
                                        return (
                                            <div
                                                style={{

                                                    width: "100%",
                                                    display: 'flex',
                                                    // justifyContent: 'center',
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                    fontSize: "16px"
                                                }}


                                            >
                                                <IoCheckmarkCircleSharp /> {item}
                                            </div>
                                        )
                                    })
                                }

                         
                          
                                


                        </div>
                    </div>


                }


            </div>


            {/* <div

                style={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(2,43,45)",
                    color: "white",
                    position: 'relative',
                    // bottom: " 13px",
                    padding: "10px"
                }}

            >
                <IoCall
                    style={{
                        fontSize: "14px",
                        marginRight: "10px",

                    }}
                />
                <h4>Call Us</h4>
            </div> */}
        </div>
    );
};

export default PopupDynamic;
