import { Carousel, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import Heading from '../../component/Heading/Heading';
import ButtonHover from '../../component/Buttons/ButtonHover/ButtonHover';
import SimpleForm from '../../component/SimpleForm/SimpleForm';
import PopupDynamic from '../../component/popup/PopupDynamic';

const HeroSection = ({ setIsThankYouPage, dialCode ,data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {heroSection} = data;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isBigScreen, setIsBigScreen] = useState(window.innerWidth >= 890);

  useEffect(() => {
    const handleResize = () => {
      setIsBigScreen(window.innerWidth >= 890);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sanitizedDescription = DOMPurify.sanitize(heroSection?.description || '');

  return (
    <div>
      <div
        style={isBigScreen ? { height: "100vh" } : { height: "400px" }}
      >
        <Carousel
          style={isBigScreen ? { height: "100vh" } : { height: "400px" }}
          autoplay
        >

           
          {
            isBigScreen ?
          
          
              heroSection?.desktopImages?.map((item, index) => (
            <div key={index} style={{ width: '100%' }}>
              <img
                src={item}
                alt={`Slide ${index}`}
                style={{
                  height: "100vh",
                  width: "100%",
                  objectFit: "cover"
                } }
              />
            </div>
            )) 
              :
              heroSection?.mobileImages?.map((item, index) => (
                <div key={index} style={{ width: '100%' }}>
                  <img
                    src={item}
                    alt={`Slide ${index}`}
                    style={ {
                      height: "400px",
                      objectFit: "cover",
                      width: "100vw",
                    }}
                  />
                </div>
              )) 
        
          }
        </Carousel>
      </div>
      <SimpleForm data = {data} dialCode={dialCode} setIsThankYouPage={setIsThankYouPage} />

      <div
        style={{
          marginTop: "-8px"
        }}
        className='container'
      >
        <Heading heading={heroSection?.heading} />
        <h1
          style={{
            fontSize: '30px',
            color: "rgba(2,43,45)"
          }}
        >
          {heroSection?.addressHeading}
        </h1>
        <h3
          style={{
            margin: "15px 0",
            color: "rgba(2,43,45)"
          }}
        >
          {heroSection?.addressSubHeading}
        </h3>
        <div
          style={{
            lineHeight: "25px"
          }}
          dangerouslySetInnerHTML={{ __html: sanitizedDescription }} // Render the sanitized HTML content
        />
        <div
          onClick={showModal}
          style={{ width: "300px", marginTop: '20px' }}
        >
          <ButtonHover icon={true} text={heroSection?.button} />
        </div>
      </div>

      <Modal
        footer={false}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <PopupDynamic
          setIsThankYouPage={setIsThankYouPage}
          heading={heroSection?.popupData?.heading}
          button={heroSection?.popupData?.button}
          dialCode={dialCode}
          data   = {data}
        />
      </Modal>
    </div>
  );
};

export default HeroSection;
