import React, { useState } from 'react';
import Heading from '../../component/Heading/Heading';
import './SiteFloorSection.css';
import { Col, Modal, Row } from 'antd';
import ButtonBackground from '../../component/Buttons/ButtonBackground/ButtonBackground';
import PopupDynamic from '../../component/popup/PopupDynamic';

const SiteFloorSection = ({ setIsThankYouPage, dialCode ,data}) => {
  const [masterPlanVisible, setMasterPlanVisible] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isFloorPlan, setIsFloorPlan] = useState(false);
  const {siteAndFloorPlan} = data

  const handleMasterPlanClick = (index) => {
    setCurrentPlan(siteAndFloorPlan?.masterPlans[index]);
    setMasterPlanVisible(true);
    setIsFloorPlan(false);
  };

  const handleFloorPlanClick = (index) => {
    setCurrentPlan(siteAndFloorPlan?.floorPlans[index]);
    setMasterPlanVisible(true);
    setIsFloorPlan(true);
  };

  return (
    <div className="container">
      <Heading heading={siteAndFloorPlan?.heading} />
      <Row>
        {siteAndFloorPlan?.masterPlans?.map((plan, index) => (
          <Col key={`masterPlan-${index}`} xs={24} sm={12} md={8}>
            <Col xs={22} style={{ margin: 'auto', marginBottom: '20px' }}>
              <h1 style={{ marginBottom: '20px', fontWeight: '300' }}>{plan?.heading}</h1>
              <div className="image-container">
                <img src={plan?.imageLink} alt="Site Plan" loading="lazy" />
                <div className="overlay"></div>
                <button onClick={() => handleMasterPlanClick(index)} className="button">
                  {plan?.button}
                </button>
              </div>
            </Col>
          </Col>
        ))}
        {siteAndFloorPlan?.floorPlans?.map((plan, index) => (
          <Col key={`floorPlan-${index}`} xs={24} sm={12} md={8}>
            <Col xs={22} style={{ margin: 'auto', marginBottom: '20px' }}>
              <div className="image-container">
                <img src={plan?.imageLink} alt="Site Plan" loading="lazy" />
                <div className="overlay"></div>
                <button onClick={() => handleFloorPlanClick(index)} className="button">
                  {plan?.hoverButton}
                </button>
              </div>
              <div onClick={() => handleFloorPlanClick(index)}>
                <ButtonBackground onClick={() => handleFloorPlanClick(index)} text={plan?.button} />
              </div>
            </Col>
          </Col>
        ))}
      </Row>
      <Modal
        open={masterPlanVisible}
        onCancel={() => setMasterPlanVisible(false)}
        footer={null}
        width={800}
      >
        <PopupDynamic
          heading={siteAndFloorPlan?.popupData?.heading}
          button={siteAndFloorPlan?.popupData?.button}
          setIsThankYouPage={setIsThankYouPage}
          dialCode={dialCode}
          data = {data}
        />
      </Modal>
    </div>
  );
};

export default SiteFloorSection;
