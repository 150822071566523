import React, { useState } from 'react'; 
import {  Modal, message as antdMessage } from 'antd'; 
import PhoneInput from 'react-phone-input-2'; 
import 'react-phone-input-2/lib/style.css'; 
import './SideForm.css'; 
import ButtonBackground from '../../component/Buttons/ButtonBackground/ButtonBackground'; 
import ButtonHover from '../../component/Buttons/ButtonHover/ButtonHover'; 
import PopupDynamic from '../../component/popup/PopupDynamic'; 

import whatsapp from '../../../assets/whatsapp.png' 
import { apiCalling } from '../../../../config/apiCalling';
const SideForm = ({ setIsThankYouPage, dialCode,data }) => { 
    const [name, setName] = useState(''); 
    const [loading, setLoading] = useState(false); 
    const [email, setEmail] = useState(''); 
    const [number, setNumber] = useState(''); 
    const [message, setMessage] = useState(''); 
    const [isModalVisible, setIsModalVisible] = useState(false); 
    const [isCallbackModalVisible, setIsCallbackModalVisible] = useState(false); 
    const [isVisitModalVisible, setIsVisitModalVisible] = useState(false); 
    const [countryCode, setCountryCode] = useState(''); 


    const handleSubmit = async (e) => { 
        e.preventDefault(); 
        if (!name) { 
            antdMessage.error('Please enter your name.'); 
            return; 
        } 

        if (!email) { 
            antdMessage.error('Please enter your email.'); 
            return; 
        } 
        if (!number) { 
            antdMessage.error('Please enter your phone number.'); 
            return; 
        } 
        const formData = { 
            name, 
            email, 
            phone: number, 
            reason: "Side form data", 
            message 
        }; 
       
        setLoading(true); 
        const dataa = await apiCalling(formData,data); 
        if (dataa === 'success') { 
            setIsThankYouPage(true); 
        } else { 
            antdMessage.error("Something went wrong please try again later."); 
        } 
        setLoading(false); 

    }; 

 

    const handleWhatsAppClick = () => { 

        const recipientNumber = data?.details?.whatsappNumber; 

        const whatsappMessage = `Hi , I am interested in M3M Golf Hills. Please share details with me`; 

        const whatsappUrl = `https://wa.me/${recipientNumber}?text=${encodeURIComponent(whatsappMessage)}`; 

        window.open(whatsappUrl, '_blank'); 

    }; 

 

 

    const handleModalOk = () => { 

        setIsModalVisible(false); 

    }; 

 

    const handleModalCancel = () => { 

        setIsModalVisible(false); 

    }; 

 

    const handleCallbackRequest = () => { 

        setIsCallbackModalVisible(true); 

    }; 

 

    const handleCallbackModalOk = () => { 

        setIsCallbackModalVisible(false); 

    }; 

 

    const handleCallbackModalCancel = () => { 

        setIsCallbackModalVisible(false); 

    }; 

 

    const handleVisitModalOk = () => { 

        setIsVisitModalVisible(false); 

    }; 

 

    const handleVisitModalCancel = () => { 

        setIsVisitModalVisible(false); 

    }; 

    return ( 
        <div style={{ height: '100vh', position: 'sticky' }}> 
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}> 
                <button 
                    style={{ width: "100%", padding: '10px 0', border: 'none', backgroundColor: "rgba(56,56,56)", color: "white", fontSize: "13px" }} 
                    onClick={() => setIsVisitModalVisible(true)} // Open visit modal 
                > 
                    {data?.sideForm?.topBarLeft?.label} 
                </button> 
                <button 
                    style={{ 
                        width: "100%", padding: '10px 0', border: 'none', backgroundColor: "rgba(56,56,56)", color: "white", fontSize: "13px", marginLeft: "2px", display: 'flex', alignItems: 'center', justifyContent: 'center', 
                    }} 
                    onClick={handleWhatsAppClick} 
                > 

                    <img 
                    src = {whatsapp} 
                        style={{ 
                            width: '15px', 
                            height: "15px", 
                            marginRight : "010px  ", 
                        }} 
                        loading="lazy" 
                    /> 
                    <div> 
                    Whatsapp  
                        </div> 
                </button> 
            </div> 
            <div style={{ backgroundColor: 'rgba(243,243,243)', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: "100%", margin: "auto", padding : "10px 0"}}> 
                <a 
                    href={`tel:${data?.details?.phoneNumber}`} 
                    style ={{ 
                        textDecoration: 'none', 
                        paddingBottom : '10px' 
                    }} 
                > 
                <ButtonHover text=  {`Call : ${data?.details?.phoneNumber}`}/>  
                </a> 

                <div style={{ backgroundColor: 'rgba(243,243,243)', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: "80%", margin: "auto" }} 
                    onClick={handleCallbackRequest} 

                > 

                    <ButtonBackground text= {data?.sideForm?.buttonAfterCallButton?.label} onClick={handleCallbackRequest} /> 

                </div> 

            </div> 

 

            <form id = {data?.projectId + "heroform"} onSubmit={handleSubmit}> 

                <h2 style={{ textAlign: 'center', margin: '20px 0' }}>Enquire Now</h2> 

                <div style={{ display: 'flex', borderBottom: '1px solid GRAY', margin: 'AUTO', width: '80%', color: 'gray', marginBottom: "20px" }}> 

                    <input 

                        placeholder='Name' 

                        style={{ border: "none", outline: 'none', paddingBottom: '8px', fontSize: "16px" }} value={name} onChange={(e) => setName(e.target.value)} required /> 

                </div> 
                <div style={{ display: 'flex', borderBottom: '1px solid GRAY', margin: 'AUTO', width: '80%', color: 'gray', marginBottom: "10px" }}> 
                    <input 
                        placeholder='Email' 
                        type="email" style={{ width: '100%', border: "none", outline: 'none', paddingBottom: '8px', fontSize: "16px" }} value={email} onChange={(e) => setEmail(e.target.value)} required /> 
                </div> 
                <div style={{ display: 'flex', borderBottom: '1px solid GRAY', margin: 'AUTO', width: '80%', color: 'gray', marginBottom: "10px" }}> 
                    <input 
                        placeholder={data?.formData?.phonePlaceholder}  
                        value ={number}
                        type="text" style={{ width: '100%', border: "none", outline: 'none', paddingBottom: '8px', fontSize: "16px" }} onChange={(e) => setNumber(e.target.value)} required /> 
                </div> 
                <div 
                    className="smallPlaceholder1" 
                    style={{ display: 'flex', marginTop: '20px', margin: 'auto', width: '80%', color: 'gray', flexDirection: 'column', marginBottom: '10px', borderBottom: '1px solid GRAY' }}> 
                    <textarea 
                        rows={data?.formData?.sideFormRows} 
                        placeholder={ 
                            data?.formData?.placeHolder 
                        } 
                        style={{ border: "none", outline: 'none', paddingBottom: '8px', fontSize: "16px" }} value={message} onChange={(e) => setMessage(e.target.value)} /> 
                </div> 
                <div style={{ width: "80%", margin: "auto", marginTop: "10px" }}> 
                    <ButtonBackground loading = {loading} text={'Enquire Now'} type="primary" htmlType="submit" /> 
                </div> 
            </form> 

 

            <Modal 
                visible={isCallbackModalVisible} 
                onOk={handleCallbackModalOk} 
                onCancel={handleCallbackModalCancel} 
                footer={null} 
            > 

                <PopupDynamic 
                    heading={data?.sideForm?.buttonAfterCallButton?.popupData?.heading} 
                    button= {data?.sideForm?.buttonAfterCallButton?.popupData?.button} 
                    dialCode={dialCode} 
                    data = {data}
                    setIsThankYouPage={setIsThankYouPage} 
                   
                /> 

            </Modal> 

 

            <Modal 

                visible={isModalVisible} 

                onOk={handleModalOk} 

                onCancel={handleModalCancel} 

                footer={null} 

            > 

                <PopupDynamic
                    heading={data?.sideForm?.topBarLeft?.popupData?.heading}
                    button={data?.sideForm?.topBarLeft?.popupData?.button}
                    setIsThankYouPage={setIsThankYouPage}
                    dialCode={dialCode} 
                    data  = {data}
                /> 
            </Modal> 
            <Modal 

                visible={isVisitModalVisible} 

                onOk={handleVisitModalOk} 

                onCancel={handleVisitModalCancel} 

                footer={null} 
            > 

                <div> 

                    <PopupDynamic 

                        heading={data?.sideForm?.topBarLeft?.popupData?.heading} 

                        button={data?.sideForm?.topBarLeft?.popupData?.button} 

                        setIsThankYouPage={setIsThankYouPage} 
                        dialCode={dialCode} 
                        data = {data}

                    /> 

                </div> 

            </Modal> 

        </div> 

    ); 

}; 

 

export default SideForm;
