import React, { useEffect, useState } from 'react';
import { Col, Modal } from 'antd';
import Heading from '../../component/Heading/Heading';
import ButtonBackground from '../../component/Buttons/ButtonBackground/ButtonBackground';

import PopupDynamic from '../../component/popup/PopupDynamic';

const PriceSection = ({ setIsThankYouPage, dialCode,data  }) => {
  const [isBigScreen, setIsBigScreen] = useState(window.innerWidth >= 700);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItemType, setSelectedItemType] = useState(null);
  
  const {price} = data
  
  const showModal = (type) => {
    setSelectedItemType(type);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  useEffect(() => {
    const handleResize = () => {
      setIsBigScreen(window.innerWidth >= 700);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='container'>
      <Heading heading={price?.heading} />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Col xs={24} md={16}>
        
          {isBigScreen ? <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr>
                {
                  price?.tableHeading?.map((item) => {
                    return (<th style={{ border: '1px solid black', padding: '8px' }}>
                      
                      {item}  
                    </th>)
                  })
                }
             
              </tr>
            </thead>
            <tbody
              style={{
                backgroundColor: "rgba(219,228,233)"
              }}
            
            >
              {price?.plans?.map((item) => (
                <tr key={item.type}>
                  <td style={{ border: '1px solid black', padding: '8px',textAlign : "center" }}>{item?.type}</td>
                  <td style={{ border: '1px solid black', padding: '8px', textAlign: "center" }}>{item.sba}</td>
                  <td style={{
                    border: '1px solid black',
                    padding: '8px',
                   
                  }}>
                    <div
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: 'center',
                  
                        height: '100%',
                        width: "100%"  
                      }}
                    >
                      <div style={{
                        width: 'auto', // Use 'auto' to make the width fit its content
                        minWidth: "80px",
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                       
                        flex: 1,paddingLeft  : "10px"
                        
                        
                      }}>
                        {item?.price}
                      </div>
                      <div
                        style={{
                          width: "65%",
                         
                        }}
                        onClick={() => showModal("Costing Breakup")}>
                        <ButtonBackground table={true} text={item?.button} />
                      </div>
                    </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </table> : <>

              {
                price?.plans?.map((e) => {
                  return <div
                    style={{
                      display: 'flex',
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor : "rgba(246,247,248)",
                      flexDirection: 'column',
                      padding: '15px 0',
                      marginBottom: "16px",
                      marginTop : "1px"
                    }}
                    
                    key={e.type}>
                    <h1
                      style={{
                        margin: " 5px 0",
                        color:"rgba(2,43,45)"
                      }}
                    
                    
                    >{e?.type}</h1>
                    <p
                      style={{
                        margin: " 5px 0",
                        color: "rgba(2,43,45)"
                      }}
                    
                    >{e?.sba}</p>
                    <h2
                    
                      style={{
                        margin: " 1px 0",
                        color: "rgba(2,43,45)"
                      }}
                    >{e?.price}</h2>
                    <div
                      style={{
                        width: "70%",
                        margin: "7px 0",
                 
                        
                      }}
                      onClick={() => showModal("Costing Breakup")}
                    >
                      <ButtonBackground table={true} text={e?.button} />
                    </div>
                  </div>;
                })
}


          </>
          
          }
        </Col>
        <Col
          xs={24} md={8}
          style={{
            display: 'flex',
            flexDirection: "column",
            padding: '0 20px '
          }}
        >
          <img
            style={{
              width: "100%",
              
              marginBottom: "21.5px",
  
              
            }}
            src="https://golfhill-gurgaon.com/m3m/assets/img/comman/sample/costing-details-320w.webp"
            alt="Costing details"
            loading="lazy"
          />
          <div
            
            onClick={() => showModal(
              "Costing Details"
            )}
          
          >
            <ButtonBackground table ={true} text="Request costing completing requesting" />
          </div>
        </Col>
      </div>
      <Modal
        footer={false}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <PopupDynamic
          heading={`Request For ${selectedItemType} `}
          button={`Request ${selectedItemType}`}
          setIsThankYouPage={setIsThankYouPage}
          dialCode={dialCode}
          data = {data}
        />
      </Modal>
    </div>
  );
}

export default PriceSection;
