import React from 'react'

import './Logo.css'

const Logo = ({data}) => {
  const { logo } = data;
  return (
    <div className='logo'>
          <img src= { logo}/>
    </div>
  )
}

export default Logo