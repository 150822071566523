import React, { useEffect, useState } from 'react'
import './Hero.css'
import SideForm from '../../components/SideForm/SideForm'
import { Carousel } from 'antd'

const Hero = ({ setIsThankYouPage, dialCode, data }) => {
  const { heroSection} = data;

  const [isBigScreen, setIsBigScreen] = useState(window.innerWidth >= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsBigScreen(window.innerWidth >= 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='hero'>
      {
        isBigScreen ?
          <Carousel
            style={isBigScreen ? { height: "83vh" } : { height: "400px" }}

          >
            {
              heroSection?.desktopImages?.map((item) => (<img
                style={{
                  height: "100vh",
                  width: "100%",
                  objectFit: "cover",
                  border: '1px solid green'
                }}
                src={item} />))
            }
          </Carousel> : <Carousel
            style={isBigScreen ? { height: "83vh" } : { height: "400px" }}

          >
            {
           heroSection?.mobileImages?.map((item) => (<img
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundSize : "cover",
                  border: '1px solid green'
                }}
                src={item} />))
            }
          </Carousel>
      }
  
      <SideForm
        data = {data}
        setIsThankYouPage={setIsThankYouPage}
        dialCode={dialCode}
      
      />
          
      </div>
  )
}

export default Hero
