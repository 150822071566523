import React from 'react'
import Heading from '../../component/Heading/Heading'

import ResponsiveCarousel from '../../component/ResponsiveCarousel/ResonsiveCarousel'
import IconCarsoul from './IconCarasoul'

const AmenitiesSection = ({ setIsThankYouPage,data }) => {
  const {amenities}  = data
  return (
    <div
      className='container'
    
    >
      <Heading
        heading={
          amenities?.heading
        }
      />
      {
        amenities?.image ? 
          
     <ResponsiveCarousel
       images={ amenities?.images}
        />  : 
          <IconCarsoul
            icons={
             amenities?.icons
            }

          />
      }
      


    </div>
  )
}

export default AmenitiesSection