import React, { useEffect, useState } from 'react';
import ButtonBackground from '../Buttons/ButtonBackground/ButtonBackground';
import Navbar from '../../Sections/Navbar/Navbar';
import CallingFooter from '../CallingFooter/CallingFooter';


const Thankyoupage = ({ setIsThankYouPage,data }) => {
   
    const [isBigScreen, setIsBigScreen] = useState(window.innerWidth >= 900);

    useEffect(() => {
        const handleResize = () => {
            setIsBigScreen(window.innerWidth >= 700);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const { thankYouPage} = data;

    return (
        <div className="thankyoupage">
            <Navbar data = {data} setIsThankYouPage={setIsThankYouPage} />
            <div
                style={
                    isBigScreen
                        ? {
                            display: 'flex',
                            backgroundColor: 'white',
                            height: '92vh',
                            width: '100vw',
                            alignItems: 'center',
                            overflow: 'hidden',
                        }
                        : {
                            display: 'flex',
                            backgroundColor: 'white',
                            height: '88vh',
                            width: '100vw',
                            alignItems: 'center',
                            flexDirection: 'column',
                            overflow: 'hidden',
                        }
                }
            >
                <div
                    style={
                        isBigScreen
                            ? {
                                width: '60%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                flexDirection: 'column',
                                display: 'flex',
                            }
                            : {
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                flexDirection: 'column',
                                marginTop: '40px',
                                paddingBottom: '20px',
                            }
                    }
                >
                    <h1
                        style={
                            isBigScreen
                                ? {
                                    fontSize: '52px',
                                    fontWeight: '800',
                                    textAlign: 'center',
                                    color: 'rgba(2,43,45)',
                                    marginTop: '40px',
                                    marginBottom: '40px',
                                }
                                : {
                                    fontSize: '32px',
                                    fontWeight: '800',
                                    textAlign: 'center',
                                    color: 'rgba(2,43,45)',
                                    marginBottom: '40px',
                                }
                        }
                    >
                        {thankYouPage?.heading}
                        <br />
                       {thankYouPage?.thankyouText}
                    </h1>
                    <h3 style={{ textAlign: 'center', width: '100%' }}>
                        {thankYouPage?.appreciateLines?.lineOne}
                        <br />
                        {thankYouPage?.appreciateLines?.lineTwo}
                    </h3>
                    <div style={isBigScreen ? { width: '30%' } : { width: '100%' }}>
                        <img
                            alt="building"
                            src={thankYouPage?.image}
                            loading="lazy" // lazy loading attribute
                        
                            style={
                                isBigScreen
                                    ? {
                                        width: '0',
                                    }
                                    : {
                                        maxHeight: '200px',
                                        marginTop: '60px',
                                        width: '100%',
                                        marginBottom: '40px',
                                    }
                            }
                        />
                    </div>
                    <div
                        style={
                            isBigScreen
                                ? { width: '300px', marginTop: '100px' }
                                : { width: '300px', margin: 'auto', marginTop: '5px' }
                        }
                        onClick={() => {
                            setIsThankYouPage(false);
                        }}
                    >
                        <ButtonBackground text={'Back to Home'} />
                    </div>
                </div>
                <div style={isBigScreen ? { width: '30%' } : { width: '100%' }}>
                    <img
                        alt="building"
                        src={thankYouPage?.image}
                        loading="lazy" // lazy loading attribute
                        style={
                            isBigScreen
                                ? {
                                    width: '500px',
                                }
                                : {
                                    width: '0%',
                                }
                        }
                    />
                </div>
            </div>
            {!isBigScreen && <CallingFooter data={data} />}
        </div>
    );
};

export default Thankyoupage;

