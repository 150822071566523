import React, { useEffect, useState } from 'react'
import GATracking from '../GATracking';
import Thankyoupage from './components/Thankyoupage/Thankyoupage';
import Navbar from './sections/Navbar/Navbar';
import Hero from './sections/Hero/Hero';
import Price from './sections/Price/Price';
import Projects from './sections/Projects/Projects';
import ImagesAndOffer from './sections/ImagesAndOffer/ImagesAndOffer';
import Amenities from './sections/Amenities/Amenities';
import AboutTheDeveloper from './sections/AboutTheDeveloper/AboutTheDeveloper';
import Footer from './sections/Footer/Footer';
import CallingFooter from './components/CallingFooter/CallingFooter';
import Loader from './components/Loader/Loader';
import './HomeSmartWorld.css'
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import Disclaimer from '../valancia/components/component/Disclaimer/Disclaimer';
import { Helmet } from 'react-helmet';
import { initializeAnalytics, logPageView } from '../config/googleAnalytics';
const HomeSmartWorld = ({ dialCode, imagesLoading, data, setImagesLoading, setDialCode, }) => {
    const params = useParams();
    const location = useLocation();
    useEffect(() => {
        initializeAnalytics(data?.googleTrackingId); // Replace with your Google Analytics ID
        logPageView(data?.googleTrackingId, location.pathname + location.search);
    }, [location]);



    const [showThankYouPage, setShowThankYouPage] = useState(false);

    const { "*": path } = params;
    const ending = path.endsWith("/disclaimer")




    if (imagesLoading) {
        return <Loader data={data} />;
    }

    return (
        <div id="smartDeveloper" className='smartDeveloper'>


            <GATracking trackingId={data?.trackingId} />
            <Helmet>
                <title>{data?.title}</title>
                <meta name="description" content={data?.description} />
                <link rel="icon" type="image/png" href={data?.favicon} sizes="16x16" />
            </Helmet>

            {


                ending || path === 'disclaimer'
                    ?

                    <Routes>
                        <Route path={`/disclaimer`} element={<Disclaimer data={data} />} />
                    </Routes>
                    :

                    <>

                        {

                            showThankYouPage ? (
                                <Thankyoupage data={data} setIsThankYouPage={setShowThankYouPage} />
                            ) : (
                                <>
                                    <Navbar
                                        data={data}
                                        setIsThankYouPage={setShowThankYouPage}
                                        dialCode={dialCode}
                                        imagesLoading={imagesLoading}
                                    />
                                    {
                                        data?.heroSection &&


                                        <Hero
                                            data={data}
                                            setIsThankYouPage={setShowThankYouPage}
                                            dialCode={dialCode}
                                            imagesLoading={imagesLoading}
                                        />
                                    }

                                    {
                                        data?.price &&

                                        <Price data={data} />
                                    }
                                    {
                                        data?.ourProjects &&



                                        <Projects
                                            data={data}
                                            setIsThankYouPage={setShowThankYouPage}
                                            dialCode={dialCode}
                                            imagesLoading={imagesLoading}
                                        />
                                    }
                                    {
                                        data.imageAndOffer &&


                                        <ImagesAndOffer data={data} />
                                    }
                                    {
                                        data?.amenities &&


                                        <Amenities data={data} />
                                    }
                                    {
                                        data?.aboutthedeveloper &&


                                        <div id="overview">
                                            <AboutTheDeveloper data={data} />
                                        </div>
                                    }
                                    {
                                        data?.footer &&
                                        <Footer data={data} />
                                    }
                                    <CallingFooter data={data} />
                                </>
                            )
                        }
                    </>
            }
        </div>
    )
}

export default HomeSmartWorld
