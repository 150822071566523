import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useParams, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import HomeSmartWorld from "./smartWorld/HomeSmartWorld.jsx";
import HomePage from "./valancia/HomePage.jsx";


//to change here
import { data } from "./config/data.js"; 
import { dataAntalya } from "./config/dataAntalya.js";
import { dataGolfhills } from "./config/dataGolfhills.js";
//

const initializeAnalytics = (trackingId) => {
  ReactGA.initialize(trackingId);
};



const App = () => {
  const [imagesLoading, setImagesLoading] = useState(false);
  const [dialCode, setDialCode] = useState("");

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <HomeSmartWorld
            data={data}
            dialCode={dialCode}
            imagesLoading={imagesLoading}
            setImagesLoading={setImagesLoading}
            setDialCode={setDialCode}
          />
        }
      />
      <Route
        path="/antalya/*"
        element={
          <HomePage
            data={dataAntalya}
            dialCode={dialCode}
            imagesLoading={imagesLoading}
            setImagesLoading={setImagesLoading}
            setDialCode={setDialCode}
          />
        }
      />
      <Route
        path="/golf-hills/*"
        element={
          <HomePage
            data={dataGolfhills}
            dialCode={dialCode}
            imagesLoading={imagesLoading}
            setImagesLoading={setImagesLoading}
            setDialCode={setDialCode}
          />
        }
      />
    </Routes>
  );
};

export default App;
