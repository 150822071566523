import React from 'react'
import './Heading.css'
const Heading = ({text,color}) => {
  return (
    <div
      style={color ? {color : color}:  {}}
      className='mainHeadingCenter'    
      >
          {text}
    </div>
  )
}

export default Heading