export const dataGolfhills = {
  trackingId: " ",
  logo: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGOLF%20Hills.png3fd03ade-8fa0-4e53-afac-bcc117f6bfd8?alt=media&token=6227db15-1035-4851-8e0f-9f5c5539dbc6",
  projectName: "M3M Golf Hills - Google Ads",
  projectId: "golfhills",
  title:
    "Buy 4.5 BHK luxury apartments at M3M Golf Hills in Sector 79, Gurgaon. Enjoy golf themed living.",
  googleTrackingId: "",
  description:
    "M3M Golf Hills offers a unique living experience with a golf-inspired design, providing a luxurious and distinctive lifestyle. The project features a wide range of amenities and facilities with beautiful views of the Aravalli hills, making it a prestigious address. Located in a prime area, M3M Golf Hills is well-connected to major destinations and essential hotspots, ensuring easy access to everything you need. It is an ideal choice for those seeking a blend of luxury, convenience, and scenic beauty, offering a truly global living experience in a serene and well-connected neighborhood.",
  favicon:
    "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FM3M.png3f493088-2237-4225-b641-8d0c3c5be3b3?alt=media&token=b7b63d81-b138-4f87-aad9-e6d89b13217c",
  heroSection: {
    mobileImages: [
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20mobile%201.webp4c76e88d-e3de-49cd-bdea-6e717de24ba4?alt=media&token=e4d3912b-994a-41c9-b5e3-b45bf410cef4",
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20Mobile%202.webpf56ee019-0a50-4c24-a29e-99a590f13046?alt=media&token=5c985708-0c61-407b-85dd-6c5f493214d4",
    ],
    desktopImages: [
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20desktop%20hero%20image%201.webp3281c5d6-dedb-4355-b925-aa74bfa096a9?alt=media&token=592eb8a4-8c6f-4281-acc8-d27d1e21938d",
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20desktop%20hero%20image%202.webp8d67a5e4-419e-4876-9862-f35056922bea?alt=media&token=87da9291-cba9-41f2-b788-e159ca1eeab7",
    ],
    heading: "Overview",
    addressHeading: "M3M Golf Hills",
    addressSubHeading: "At Sector 79, Gurugram By M3M India",
    description:
      "M3M Golf Hills offers a unique living experience with a golf-inspired design, providing a luxurious and distinctive lifestyle. The project features a wide range of amenities and facilities with beautiful views of the Aravalli hills, making it a prestigious address. Located in a prime area, M3M Golf Hills is well-connected to major destinations and essential hotspots, ensuring easy access to everything you need. It is an ideal choice for those seeking a blend of luxury, convenience, and scenic beauty, offering a truly global living experience in a serene and well-connected neighborhood. </br></br> The M3M Golf Hills project offers a luxurious living experience with its unique features: </br></br> <b>Golf Themed Central Landscape</b>: The development includes a beautifully designed central landscape inspired by golf, providing a serene and sophisticated environment.</br></br> <b>Spectacular Views of Aravalis</b>: Residents can enjoy breathtaking views of the Aravali hills from their spacious balconies, enhancing the living experience with natural beauty.</br></br> <b>World-Class Sports Facilities</b>: The project is equipped with top-notch sports facilities, catering to fitness enthusiasts and sports lovers.</br></br> <b>Zen Garden & Viewing Decks</b>: For those seeking tranquility, the Zen Garden and viewing decks offer peaceful spaces to relax and unwind.</br></br>",
    button: "Download Brochure",
    popupData: {
      heading: "Download Brochure ",
      button: "Download Brochure",
    },
  },

  //card data
  cardData: {
    popupData: {
      heading: "Enquire ",
      button: "Enquire",
    },

    heading: "New Phase Launch",
    mainHeading: "M3M Golf Hills",
    address: "in Sector 79, Gurugram",
    subAddress: "By M3M India",
    fields: [
      { key: "55 Acres", label: "Land Area" },
      { key: "2028", label: "Possession" },
      { key: "G+30", label: "Floors" },
    ],
    buttonData: {
      discount: "Launched Now!",
      plantTime: "Luxury 4.5 BHKs",
      edition: "Golf Themed Living",
    },
    description: "Express your interest today!",
    price: "4.01 Cr Onwards",
    // button: "Book your VC Now",
    rera: " RERA No.: RC/REP/HARERA/GGM/8O9/541/2024/36",
  },

  //Price
  price: {
    heading: "Tentative Price",
    tableHeading: ["Type", "Area", "Price"],
    plans: [
      {
        type: "4.5 BHK + S",
        sba: "2685 sq ft (approx.)",
        price: "Rs. 4.01 Cr onwards",
        button: "Request For Details",
      },
    ],
  },

  //Site & Floor Plan
  siteAndFloorPlan: {
    heading: "Site & Floor Plan",
    popupData: {
      heading: "Request for plan details",
      button: "Request for plan details ",
    },
    floorPlans: [
      {
        heading: "Master Plan Plan",
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20masterplan.webp24c7f9b5-9f84-47f8-9c83-0f6dc9013568?alt=media&token=fd29ca4b-c2e3-40f5-a933-0bca82d6aaee",
        button: "Master Plan",
        hoverButton: "Enquire Now",
      },
      {
        heading: "Floor Plan",
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%204%20bhk%20plan.webpa69681a3-4a77-4258-85cb-88f9cad47aa9?alt=media&token=a0e7da48-df1d-4e38-9d76-2fa8f849ab12",
        button: "4.5 BHK + S",
        hoverButton: "Enquire Now",
      },
    ],
  },
  //AMENITIES
  amenities: {
    heading: "Amenities",
    image: false,
    images: [
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Famp.svgcdd31981-cadc-466e-b4ca-39b0646e70f0?alt=media&token=07c20efe-e5e5-4517-b133-26ee07a641c3",
        bottomSideHeading: "AMPHITHEATRE",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fbasketball-court.svg6a0442ac-3a47-492e-aa67-b33fd7672a47?alt=media&token=a3fcfd38-5006-4686-aeec-41ed7ad3f11a",
        bottomSideHeading: "BASKETBALL COURT",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fcricket.svgdc6f93ec-beec-4839-a7c3-e028d5ef5f00?alt=media&token=9891bd72-9d16-4d73-8774-13bd68b29639",
        bottomSideHeading: "CRICKET PRACTICE NET",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftable-tennis.svgcae56e9d-c1b2-43a8-8b36-62f46a0162a8?alt=media&token=8aeaa039-24fc-43af-90eb-a0e1145a3396",
        bottomSideHeading: "TABLE TENNIS",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fjogging.svg9aa328dd-c386-4bd4-9751-b5d87f9a8006?alt=media&token=10dcca65-1c34-4152-8ddb-a474574cb92a",
        bottomSideHeading: "JOGGING TRACK",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fgym.svga6462c92-2def-4067-9c45-c9013ee32c2b?alt=media&token=39cf2c2f-edb0-4aff-bf8d-bb9c63058dd3",
        bottomSideHeading: "GYM",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Freflexology.svg272a3d7e-481c-4f3e-a0a3-8f8ddfe88bfb?alt=media&token=e36ab038-8c51-4836-bee0-c1acfb379f01",
        bottomSideHeading: "REFLEXOLOGY PATHWAY",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fswm.svg0936427f-6d8c-44a1-b5ad-e172b28b65c4?alt=media&token=ccb6d048-d265-4c33-bd26-56b3f1ac1edb",
        bottomSideHeading: "SWIMMING POOL",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fkids.svge0ffa078-52ac-44ec-ae4e-1b843e328fe8?alt=media&token=1ffe893d-8644-4c14-b94b-bd531d98b773",
        bottomSideHeading: "KIDS PLAY AREA",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftrack-bicycle.svgb2654b89-e1be-4fdd-ade2-54cbbcf12175?alt=media&token=194a5e91-96c1-471d-8d8d-0c06dc9ad0db",
        bottomSideHeading: "CYCLING TRACK",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fyoga.svg220df747-446e-49da-afd7-eb796a43bee5?alt=media&token=8239ab9b-75a4-4f89-bb74-4dc49aaf6ceb",
        bottomSideHeading: "YOGA LAWN",
      },
    ],
    icons: [
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Famp.svgcdd31981-cadc-466e-b4ca-39b0646e70f0?alt=media&token=07c20efe-e5e5-4517-b133-26ee07a641c3",
        bottomSideHeading: "AMPHITHEATRE",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fbasketball-court.svg6a0442ac-3a47-492e-aa67-b33fd7672a47?alt=media&token=a3fcfd38-5006-4686-aeec-41ed7ad3f11a",
        bottomSideHeading: "BASKETBALL COURT",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fcricket.svgdc6f93ec-beec-4839-a7c3-e028d5ef5f00?alt=media&token=9891bd72-9d16-4d73-8774-13bd68b29639",
        bottomSideHeading: "CRICKET PRACTICE NET",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftable-tennis.svgcae56e9d-c1b2-43a8-8b36-62f46a0162a8?alt=media&token=8aeaa039-24fc-43af-90eb-a0e1145a3396",
        bottomSideHeading: "TABLE TENNIS",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fjogging.svg9aa328dd-c386-4bd4-9751-b5d87f9a8006?alt=media&token=10dcca65-1c34-4152-8ddb-a474574cb92a",
        bottomSideHeading: "JOGGING TRACK",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fgym.svga6462c92-2def-4067-9c45-c9013ee32c2b?alt=media&token=39cf2c2f-edb0-4aff-bf8d-bb9c63058dd3",
        bottomSideHeading: "GYM",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Freflexology.svg272a3d7e-481c-4f3e-a0a3-8f8ddfe88bfb?alt=media&token=e36ab038-8c51-4836-bee0-c1acfb379f01",
        bottomSideHeading: "REFLEXOLOGY PATHWAY",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fswm.svg0936427f-6d8c-44a1-b5ad-e172b28b65c4?alt=media&token=ccb6d048-d265-4c33-bd26-56b3f1ac1edb",
        bottomSideHeading: "SWIMMING POOL",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fkids.svge0ffa078-52ac-44ec-ae4e-1b843e328fe8?alt=media&token=1ffe893d-8644-4c14-b94b-bd531d98b773",
        bottomSideHeading: "KIDS PLAY AREA",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftrack-bicycle.svgb2654b89-e1be-4fdd-ade2-54cbbcf12175?alt=media&token=194a5e91-96c1-471d-8d8d-0c06dc9ad0db",
        bottomSideHeading: "CYCLING TRACK",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fyoga.svg220df747-446e-49da-afd7-eb796a43bee5?alt=media&token=8239ab9b-75a4-4f89-bb74-4dc49aaf6ceb",
        bottomSideHeading: "YOGA LAWN",
      },
    ],
  },
  //Gallery
  gallery: {
    heading: "Gallery",
    images: [
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20gallery%201.webp8e53ec80-ef09-45a5-b542-89c61c1bbffa?alt=media&token=ca44b4f4-9d21-44e3-abd9-9d64e8d09ea0",
        bottomSideHeading: "Artistic Impression",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20gallery%202.webp0d6208e1-d0b4-4f6b-9638-7d1b9bb3d607?alt=media&token=66f0b70c-8525-42b2-b9ab-20090950b0a7",
        bottomSideHeading: "Artistic Impression",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20gallery%203.webp54c224dd-0247-4f8b-8f66-72bb34c63b3c?alt=media&token=6a0b8310-4a9f-40ef-a325-a21bfa604c12",
        bottomSideHeading: "Artistic Impression",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20gallery%204.webp60e419c7-f87b-4902-b70d-8e00938c5377?alt=media&token=8bee35f7-e47e-48e5-a86f-a70765766458",
        bottomSideHeading: "Artistic Impression",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20gallery%205.webp67c201cc-178f-4da2-aa25-e429333a829f?alt=media&token=de80885f-6592-428a-bbb1-79dfe0c73b23",
        bottomSideHeading: "Artistic Impression",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20gallery%206.webp47df340b-faf5-43fc-ac14-7ae2f9473305?alt=media&token=8002e84c-bb2b-4c3f-886e-d0e708eec8a3",
        bottomSideHeading: "Artistic Impression",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20gallery%207.webpfdcbc72d-2100-451c-aead-267283f15ac6?alt=media&token=10940034-9287-4f04-b041-57c9dc7388d8",
        bottomSideHeading: "Artistic Impression",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20gallery%208.webpf6a3c30c-2ed8-4221-a7a3-90db8237a6ca?alt=media&token=8fb688ee-849b-4507-815a-aa8427a6e8a0",
        bottomSideHeading: "Artistic Impression",
      },
    ],
  },
  //LOCATION
  location: {
    heading: "Location",
    mapView: {
      heading: "Map View",
      mapLink:
        "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d5750.784674353985!2d76.96613474336704!3d28.36450566839613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sin!4v1724322660708!5m2!1sen!2sin",
      button: "Know More About Location",
      popupData: {
        heading: "Explore Location",
        button: "Request Location",
      },
    },
    locationMap: {
      heading: "Location Map",
      imageLink:
        "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20location%20map.jpg398e7e2b-458b-4d6e-8f63-39d846e480ce?alt=media&token=dde4c1f7-80d4-449f-b3bc-d2a23524719f",
      button: "View Location Map",
      popupData: {
        heading: "Explore Location",
        button: "Request Location",
      },
    },
  },
  //Virutal  Taur
  virtualSiteTour: {
    heading: "Virtual Tour",
    items: [
      {
        heading: "VIRTUAL SITE TOUR",
        subHeading: "M3M Golf Hills at Sector 79, Gurugram",
        bottomSideHeading: "Artistic Impression",
        topSideHeading: "",
        backgroundImage:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20video.webp7f6079b6-7c9a-43ab-9a84-d5c8ef13cb06?alt=media&token=f15a2a72-d15e-4c47-83b6-6aedaaf20f0f",
        popupData: {
          heading: "Request For Virtual Site Tour",
          button: "Virtual Site Tour",
        },
      },
      {
        heading: "SAMPLE FLAT",
        subHeading: "M3M Golf Hills at Sector 79, Gurugram",
        bottomSideHeading: "Artistic Impression",
        // topSideHeading: "Unleash Your Golfing Skills on Breathtaking 9-Hole Chip & Golf Greens",
        backgroundImage:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20sample%20apt.webp08cadf9e-da6d-4b6c-9687-8495fdf4efec?alt=media&token=0c367d2e-0abe-437d-b7f2-3c760dc5289f",
        popupData: {
          heading: "Request For Virtual Site Tour",
          button: "Virtual Site Tour",
        },
      },
    ],
  },
  //about the developer
  aboutthedeveloper: {
    heading: "About The Developer",
    para: "M3M India, established in 2010, is one of the fastest-growing real estate developers in the country.Over time, M3M India has emerged as a game-changer in the luxury, residential, and retail segments of the real estate industry in the country. <br></br> M3M India has about 50 projects to its credit in Gurugram itself and delivered all the projects launched prior to 2019. Construction is in full swing in the rest of the projects and all the projects shall be delivered within timelines. As of now, M3M India Group is proud to have in its kitty about 30 million square feet of saleable space.<br></br> With an impressive land bank of 3,000 acres, M3M India operates on the principles of 3Ts - Trust, Transparency and Timely delivery.  From the very beginning, the strategy has been to build an integrated business model where, from owning land to monetizing, licensing, launching, marketing, delivery and after-sales, the complete value chain is managed internally. Managing these critical factors directly also saves time, costs, and projects are better integrated in terms of facilities and monitoring.",
  },
  footer: {
    topLine: "Channel Partner RERA No. : RC/REA/HARERA/GGM/1327/922/2O2O/26",
    bottomLine: "Project RERA No. : RC/REP/HARERA/GGM/8O9/541/2024/36",
    link: "https://haryanarera.gov.in/",
    disclimer:
      "This is not the official website of the developer & property, it belongs to an authorised channel partner for information purposes only. All rights for logos & images are reserved to the developer. Thank you for visiting our website. This disclaimer Disclaimer is applicable to this website and all microsites and websites owned by us. By using or accessing this website you agree with the Disclaimer without any qualification or limitation. This website is in the process of being updated. By accessing this website, the viewer confirms that the information including brochures and marketing collaterals on this website are solely for informational purposes only and the viewer has not relied on this information for making any booking/purchase in any project of the company. Nothing on this website, constitutes advertising, marketing, booking, selling or an offer for sale, or invitation to purchase a unit in any project by the company. The company is not liable for any consequence of any action taken by the viewer relying on such material/information on this website.",
    footerAllRight:
      "All Rights Reserved. © 2024 Copyright PropCrown Global LLP",
  },
  details: {
    whatsappNumber: "+919990887599",
    phoneNumber: "+919990887599",
    whatsappMessage:
      "Hi, I am interested in M3M Golf Hills. Please share the details with me.",
  },
  formData: {
    phonePlaceholder: "Phone number with country code",
    placeHolder: "Please suggest a time (in IST) to get in touch.",
    popup: {
      rows: 3,
    },
    sideFormRows: 2,
  },
  //thank you form
  thankYouPage: {
    heading: "Thank You",
    thankyouText: "for filling the form",
    image:
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20thank%20you.webp9d7ab792-a70e-4391-ae06-b5d23ce0b12e?alt=media&token=60ef24df-baba-4f93-a870-c36873f21a99",
    appreciateLines: {
      lineOne: "We appreciate your interest",
      lineTwo: "and will get back to you shortly.",
    },
  },
  disclaimer: `
    <section>
      <h2>Disclaimer</h2>
      <p>
        This website is only for the purpose of providing information regarding real estate projects in different regions. By accessing this website, the viewer confirms that the information including brochures and marketing collaterals on this website is solely for informational purposes and the viewer has not relied on this information for making any booking/purchase in any project of the company. Nothing on this website constitutes advertising, marketing, booking, selling or an offer for sale, or invitation to purchase a unit in any project by the company. The company is not liable for any consequence of any action taken by the viewer relying on such material/ information on this website.
      </p>
      <p>
        Please also note that the company has not verified the information and the compliances of the projects. Further, the company has not checked the RERA (Real Estate Regulation Act 2016) registration status of the real estate projects listed herein. The company does not make any representation in regards to the compliances done against these projects. You should make yourself aware of the RERA registration status of the listed real estate projects before purchasing property.
      </p>
      <p>
        This site is for information purposes only and should not be treated as the official website.
      </p>
    </section>
    <section>
      <h2>Privacy Policy</h2>
      <h3>Updation of privacy policy</h3>
      <p>
        This privacy policy is subject to undergo change and review without any prior notice or approval. So to keep yourself updated on the changes introduced, please keep visiting and reviewing the terms and conditions of this privacy policy.
      </p>
      <h3>User information</h3>
      <p>
        By using our website, you agree to abide by the rules laid out by us and consent to the collection and use of all such information that you may furnish to, or through, our website. In some cases, while you visit our website, you may not need to provide any personal information. But in certain instances, we must have your personal information in order for us to grant you access to some of the links or sites. Such links/ pages may ask for your name, e-mail address, phone number, etc. The information furnished by you is used to provide relevant products and services and to acknowledge receipt of your communication or to send out information and updates to you. You have the option of requesting removal from our mailing list. We do not give away your personal information to any third party.
      </p>
      <h3>Security</h3>
      <p>
        To ensure security while transferring sensitive information, all the ongoing transmissions between client and server are encrypted using advanced and standard protocols. We also practice restricted access by employees and hold them to high levels of confidentiality.
      </p>
      <h3>Use of cookies</h3>
      <p>
        We may use cookies for security, session continuity, and customization purposes. In case of a user opting to reject a cookie, he/ she may not be able to gain access to some of the limited services or use some features of the site.
      </p>
      <p>
        In case of any queries or suggestions regarding the privacy statement or your dealings with this website, please contact:
      </p>
    </section>
  `,
  popup: {
    leftSide: {
      heading: "We Promise",
      iconsImages: [
        {
          label: "Instant Call Back",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/imageuploader-7809e.appspot.com/o/images%2Fcall-back-icon-16.jpgfeb2b5f4-9b58-4eae-87e9-c8aca81d5e45?alt=media&token=bb54e66f-5637-4ca0-9f40-2008ee8b2802",
        },
        {
          label: "Project Video Conferencing",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FVC.svgcb712f12-aac9-492a-a2f7-74c63a3918ea?alt=media&token=3848f904-807c-4e6c-9751-832759017e10",
        },
        {
          label: "Unmatched Price",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/imageuploader-7809e.appspot.com/o/images%2Fbest-deal-badge-icon-best-deal-banners-badge-sticker-sign-tag-best-offer-modern-style-illustration-vector.jpg2c5a7144-c7c9-4913-b229-5280ed00fdc4?alt=media&token=ddf9f9c3-d403-4961-9557-850792a7b112",
        },
      ],
    },
    rightSide: {
      heading: "Project Highlights",
      points: [
        "RERA Received Now",
        "Excellent Connectivity",
        "Golf Themed Living",
        "Preference for NRIs",
      ],
    },
  },
  sideForm: {
    topBarLeft: {
      label: "Organise Site Visit",
      popupData: {
        heading: "Request For Virtual Site Tour",
        button: "Virtual Site Tour",
      },
      topBarRight: {
        label: "Whatsapp",
        imageLink: "",
      },
    },
    buttonAfterCallButton: {
      label: "Request for Call Back",
      popupData: {
        heading: "Request For Call Back",
        button: "Request For Call Now ",
      },
    },
  },
  backendUrl: "https://landing-pages-backend.onrender.com",
};
  //image ratio
  // hero = >
  //   desktop : 1183*730
  //   mobile : 390*400
  //logo =>92*54
  //price => 420*227
  // floorPlan => 425*319
  //amenites => square
  //gallery => square
  //location =>687*310
  //video => 569*323


