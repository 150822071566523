export const data = {
  homepageUrl: "https://www.m3mprop.com/",
  projectName: "M3M Gurgaon - Google Ads",
  title:
    "Explore M3M residential projects Antalya Hills and Golf Hills in Sector 79, Gurgaon, starting from Rs. 1.67 Cr!",
  description: " ",
  projectId: "m3mprop",
  favicon:
    "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FM3M.png3f493088-2237-4225-b641-8d0c3c5be3b3?alt=media&token=b7b63d81-b138-4f87-aad9-e6d89b13217c",

  logo: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGOLF%20Hills%20(1).pngab035c5a-4eba-4866-819f-fb681c83802d?alt=media&token=4c028466-267a-4a08-b90e-1ce889e6c4da",

  headers: ["OVERVIEW", "BOOK A SITE VISIT", "CONTACT US"],
  heroSection: {
    desktopImages: [
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20desktop%20hero%20image%201.webp3281c5d6-dedb-4355-b925-aa74bfa096a9?alt=media&token=592eb8a4-8c6f-4281-acc8-d27d1e21938d",
      "  https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FM3M%20desktop%20hero%20image%201.webp4ea0019e-65a2-4a71-ac12-c29c34fb7016?alt=media&token=863fcc75-ad29-47e8-ba90-c7a6bd85ea73",
    ],
    mobileImages: [
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20mobile%201.webp4c76e88d-e3de-49cd-bdea-6e717de24ba4?alt=media&token=e4d3912b-994a-41c9-b5e3-b45bf410cef4",
      " https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FM3M%20ANTALYA%20mobile%20hero%20image%201.webp9684f47b-c8b9-4b9c-a3d6-18cb2cf2234d?alt=media&token=7a945669-bfdb-4a11-8c74-6da052f61758",
    ],
  },
  frontHighLightedCard: {
    heading: "M3M Gurugram",
    animatingHighLight: {
      imageLink:
        "https://firebasestorage.googleapis.com/v0/b/imageuploader-7809e.appspot.com/o/images%2FScreenshot%202024-07-29%20002153.pnga1dc8201-7ab1-432e-a0b3-91a574154f0c?alt=media&token=f56164fa-3453-47ba-b1fe-b0785f859db5",
      label: "Schedule your VC with the M3M Team",
    },
    secondHighlight: {
      lineOne: "2.5 BHK, 3.5 BHK & 4.5 BHK floors & apartments",
      lineTwo: "Luxury & Ultra Luxury Condos",
    },
    thirdHighLight: {
      textOne: "Starts From : ",
      textTwo: "Rs.",
      textThree: "1.67 Cr*",
    },
    getInTouch: {
      heading: {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/imageuploader-7809e.appspot.com/o/images%2Fwriting-edit-svgrepo-com.svgf78eb296-c236-48f2-90c2-719aafd891f7?alt=media&token=351f9c8a-720d-4971-9236-8136f8c126b5",
        label: "Get in touch with us!",
      },
      popupHeading: "Starts From : 1.67 Cr* Onwards ",
      formData: {
        labelOne: "Name",
        labelTwo: "Email",
        labelThree: "Mobile No.",
        labelFour: "Phone number with country code",
        labelFive: "Please suggest a time (in IST) to get in touch.",
        rows: "2",
        button: "Submit",
      },
    },
    highlightingNumber: "Call : +91-9990887599",
  },
  // price: {
  //   heading: "Tentative Price (incl. of GST)",
  //   tableHeading: ["Type", "Area", "Price"],
  //   plans: [
  //     {
  //       type: "1 BHK",
  //       sba: "600 sq ft (approx.)",
  //       price: "Rs. 60 Lacs",
  //       button: "Request For Details",
  //     },
  //     {
  //       type: "2 BHK",
  //       sba: "1150 sq ft (approx.)",
  //       price: "On request",
  //       button: "Request For Details",
  //     },
  //     {
  //       type: "2.5 BHK",
  //       sba: "1275 sq ft (approx.)",
  //       price: "On request",
  //       button: "Request For Details",
  //     },
  //     {
  //       type: "3 BHK + 2T",
  //       sba: "1550 sq ft (approx.)",
  //       price: "On request",
  //       button: "Request For Details",
  //     },
  //     {
  //       type: "3 BHK + 3T",
  //       sba: "1750 sq ft (approx.)",
  //       price: "On request",
  //       button: "Request For Details",
  //     },
  //     {
  //       type: "4 BHK",
  //       sba: "2400 sq ft (approx.)",
  //       price: "On request",
  //       button: "Request For Details",
  //     },
  //   ],
  // },
  ourProjects: {
    heading: "Our Projects",
    comingSoonImage:
      "https://firebasestorage.googleapis.com/v0/b/imageuploader-7809e.appspot.com/o/images%2FScreenshot%202024-07-29%20002153.pnga1dc8201-7ab1-432e-a0b3-91a574154f0c?alt=media&token=f56164fa-3453-47ba-b1fe-b0785f859db5",
    cards: [
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FM3M%20ANTALYA%20card.webpe9a90307-0501-4cf2-8167-e2fcf5c884be?alt=media&token=1726c06f-fc5f-45eb-bbfa-9456ee974132",
        projectName: "M3M Antalya Hills",
        locationName: "Sector 79, Gurgaon",
        projectUrl: "/antalya",
        projectDetails: [
          {
            label: "Unit Type",
            value: "2.5 BHK & 3.5 BHK Luxury Floors",
          },
          {
            label: "Starting",
            value: "₹ 1.67 Cr Onwards",
          },
        ],
        homeHeistScheme: {
          label: "Key Benefits",
          value: [
            "Low rise floors community",
            "Launch benefits",
            "Possession by 2027",
          ],
        },
        button: "View Details",
        comingSoon: true,
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FGolf%20Hills%20card.webp280b98c3-ba11-4a27-ac22-0d48d429296c?alt=media&token=1d1980a9-98d2-42d6-be6a-f7aa63c1cce7",
        projectName: "M3M Golf Hills",
        locationName: "Sector 79, Gurgaon",
        projectUrl: "/golf-hills",
        projectDetails: [
          {
            label: "Unit Type",
            value: "4.5 BHK Luxury Apartments",
          },
          {
            label: "Starting",
            value: "₹ 4.01 Cr Onwards",
          },
        ],
        homeHeistScheme: {
          label: "Key Benefits",
          value: [
            "Golf themed luxury living",
            "Special payment plan",
            "Possession by 2028",
          ],
        },
        button: "View Details",
        comingSoon: true,
      },
    ],
  },
  // imageAndOffer: {
  //   imageLink:
  //     "https://firebasestorage.googleapis.com/v0/b/imageuploader-7809e.appspot.com/o/images%2FScreenshot%202024-07-30%20000113.png30d9b6a7-004d-4da4-bd4d-f38a84e4fa38?alt=media&token=09fc1320-f5f5-4df9-b06a-ba20b7e62802",
  //   cards: [
  //     {
  //       heading: "USE THE TOKYO CARD",
  //       points: [
  //         "Pay Just & RESERVE <b>5 LACKS </b> THE UNIT",
  //         "Pay Just & RESERVE <b>5 LACKS </b> THE UNIT",
  //       ],
  //     },
  //     {
  //       heading: "USE THE TOKYO CARD",
  //       points: [
  //         "Pay Just & RESERVE <b>5 LACKS </b> THE UNIT",
  //         "Pay Just & RESERVE <b>5 LACKS </b> THE UNIT",
  //       ],
  //     },
  //     {
  //       heading: "USE THE TOKYO CARD",
  //       points: [
  //         "Pay Just & RESERVE <b>5 LACKS </b> THE UNIT",
  //         "Pay Just & RESERVE <b>5 LACKS </b> THE UNIT",
  //       ],
  //     },
  //   ],
  // },
  // amenities: {
  //   heading: "Amenities",
  //   icons: [
  //     {
  //       imageLink:
  //         "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Famp.svgcdd31981-cadc-466e-b4ca-39b0646e70f0?alt=media&token=07c20efe-e5e5-4517-b133-26ee07a641c3",
  //       bottomSideHeading: "AMPHITHEATRE",
  //     },
  //     {
  //       imageLink:
  //         "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fbasketball-court.svg6a0442ac-3a47-492e-aa67-b33fd7672a47?alt=media&token=a3fcfd38-5006-4686-aeec-41ed7ad3f11a",
  //       bottomSideHeading: "BASKETBALL COURT",
  //     },
  //     {
  //       imageLink:
  //         "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fcricket.svgdc6f93ec-beec-4839-a7c3-e028d5ef5f00?alt=media&token=9891bd72-9d16-4d73-8774-13bd68b29639",
  //       bottomSideHeading: "CRICKET PRACTICE NET",
  //     },
  //     {
  //       imageLink:
  //         "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftable-tennis.svgcae56e9d-c1b2-43a8-8b36-62f46a0162a8?alt=media&token=8aeaa039-24fc-43af-90eb-a0e1145a3396",
  //       bottomSideHeading: "TABLE TENNIS",
  //     },
  //     {
  //       imageLink:
  //         "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fjogging.svg9aa328dd-c386-4bd4-9751-b5d87f9a8006?alt=media&token=10dcca65-1c34-4152-8ddb-a474574cb92a",
  //       bottomSideHeading: "JOGGING TRACK",
  //     },
  //     {
  //       imageLink:
  //         "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fgym.svga6462c92-2def-4067-9c45-c9013ee32c2b?alt=media&token=39cf2c2f-edb0-4aff-bf8d-bb9c63058dd3",
  //       bottomSideHeading: "GYM",
  //     },
  //     {
  //       imageLink:
  //         "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Freflexology.svg272a3d7e-481c-4f3e-a0a3-8f8ddfe88bfb?alt=media&token=e36ab038-8c51-4836-bee0-c1acfb379f01",
  //       bottomSideHeading: "REFLEXOLOGY PATHWAY",
  //     },
  //     {
  //       imageLink:
  //         "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fswm.svg0936427f-6d8c-44a1-b5ad-e172b28b65c4?alt=media&token=ccb6d048-d265-4c33-bd26-56b3f1ac1edb",
  //       bottomSideHeading: "SWIMMING POOL",
  //     },
  //     {
  //       imageLink:
  //         "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fkids.svge0ffa078-52ac-44ec-ae4e-1b843e328fe8?alt=media&token=1ffe893d-8644-4c14-b94b-bd531d98b773",
  //       bottomSideHeading: "KIDS PLAY AREA",
  //     },
  //     {
  //       imageLink:
  //         "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftrack-bicycle.svgb2654b89-e1be-4fdd-ade2-54cbbcf12175?alt=media&token=194a5e91-96c1-471d-8d8d-0c06dc9ad0db",
  //       bottomSideHeading: "CYCLING TRACK",
  //     },
  //     {
  //       imageLink:
  //         "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fyoga.svg220df747-446e-49da-afd7-eb796a43bee5?alt=media&token=8239ab9b-75a4-4f89-bb74-4dc49aaf6ceb",
  //       bottomSideHeading: "YOGA LAWN",
  //     },
  //   ],
  // },
  aboutthedeveloper: {
    heading: "About The Developer",
    mainHeading: "M3M India",
    imageLink:
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FM3M.png3f493088-2237-4225-b641-8d0c3c5be3b3?alt=media&token=b7b63d81-b138-4f87-aad9-e6d89b13217c",
    para: "M3M India, established in 2010, is one of the fastest-growing real estate developers in the country.Over time, M3M India has emerged as a game-changer in the luxury, residential, and retail segments of the real estate industry in the country. <br></br> M3M India has about 50 projects to its credit in Gurugram itself and delivered all the projects launched prior to 2019. Construction is in full swing in the rest of the projects and all the projects shall be delivered within timelines. As of now, M3M India Group is proud to have in its kitty about 30 million square feet of saleable space.<br></br> With an impressive land bank of 3,000 acres, M3M India operates on the principles of 3Ts - Trust, Transparency and Timely delivery.  From the very beginning, the strategy has been to build an integrated business model where, from owning land to monetizing, licensing, launching, marketing, delivery and after-sales, the complete value chain is managed internally. Managing these critical factors directly also saves time, costs, and projects are better integrated in terms of facilities and monitoring.",
  },
  footer: {
    topLine: "Channel Partner RERA No. : RC/REA/HARERA/GGM/1327/922/2O2O/26",
    // bottomLine: "Project RERA No. : ",
    link: "https://haryanarera.gov.in/",
    disclimer:
      "This is not the official website of the developer & property, it belongs to an authorised channel partner for information purposes only. All rights for logos & images are reserved to the developer. Thank you for visiting our website. This disclaimer Disclaimer is applicable to this website and all microsites and websites owned by us. By using or accessing this website you agree with the Disclaimer without any qualification or limitation. This website is in the process of being updated. By accessing this website, the viewer confirms that the information including brochures and marketing collaterals on this website are solely for informational purposes only and the viewer has not relied on this information for making any booking/purchase in any project of the company. Nothing on this website, constitutes advertising, marketing, booking, selling or an offer for sale, or invitation to purchase a unit in any project by the company. The company is not liable for any consequence of any action taken by the viewer relying on such material/information on this website.",
    footerAllRight:
      "All Rights Reserved. © 2024 Copyright PropCrown Global LLP",
  },
  thankYouPage: {
    heading: "Thank You",
    thankyouText: "for filling the form",
    image: "",
    appreciateLines: {
      lineOne: "We appreciate your interest",
      lineTwo: "and you'll reacieve a call back shortly.",
    },
    backToHome: "Back to Home",
  },
  disclaimer: `
  <section>
    <h2>Disclaimer</h2>
    <p>
      This website is only for the purpose of providing information regarding real estate projects in different regions. By accessing this website, the viewer confirms that the information including brochures and marketing collaterals on this website is solely for informational purposes and the viewer has not relied on this information for making any booking/purchase in any project of the company. Nothing on this website constitutes advertising, marketing, booking, selling or an offer for sale, or invitation to purchase a unit in any project by the company. The company is not liable for any consequence of any action taken by the viewer relying on such material/ information on this website.
    </p>
    <p>
      Please also note that the company has not verified the information and the compliances of the projects. Further, the company has not checked the RERA (Real Estate Regulation Act 2016) registration status of the real estate projects listed herein. The company does not make any representation in regards to the compliances done against these projects. You should make yourself aware of the RERA registration status of the listed real estate projects before purchasing property.
    </p>
    <p>
      This site is for information purposes only and should not be treated as the official website.
    </p>
  </section>
  <section>
    <h2>Privacy Policy</h2>
    <h3>Updation of privacy policy</h3>
    <p>
      This privacy policy is subject to undergo change and review without any prior notice or approval. So to keep yourself updated on the changes introduced, please keep visiting and reviewing the terms and conditions of this privacy policy.
    </p>
    <h3>User information</h3>
    <p>
      By using our website, you agree to abide by the rules laid out by us and consent to the collection and use of all such information that you may furnish to, or through, our website. In some cases, while you visit our website, you may not need to provide any personal information. But in certain instances, we must have your personal information in order for us to grant you access to some of the links or sites. Such links/ pages may ask for your name, e-mail address, phone number, etc. The information furnished by you is used to provide relevant products and services and to acknowledge receipt of your communication or to send out information and updates to you. You have the option of requesting removal from our mailing list. We do not give away your personal information to any third party.
    </p>
    <h3>Security</h3>
    <p>
      To ensure security while transferring sensitive information, all the ongoing transmissions between client and server are encrypted using advanced and standard protocols. We also practice restricted access by employees and hold them to high levels of confidentiality.
    </p>
    <h3>Use of cookies</h3>
    <p>
      We may use cookies for security, session continuity, and customization purposes. In case of a user opting to reject a cookie, he/ she may not be able to gain access to some of the limited services or use some features of the site.
    </p>
    <p>
      In case of any queries or suggestions regarding the privacy statement or your dealings with this website, please contact:
    </p>
  </section>
`,
  details: {
    whatsappNumber: "+919990887599",
    phoneNumber: "+919990887599",
    whatsappMessage:
      "Hi, I am interested in M3M Projects in Gurgaon. Please share the details with me.",
  },
  backendUrl: "https://realback.vercel.app",
};
// image sizes:
// logo - 391x97
// Our project - 362x207
// Home Heist Banner - 377x303
// Diamond - 71x60
// Thank you page - 500x281
