export const dataAntalya = {
  logo: "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FUntitled%20design%20(2).png4e28661d-70ae-4524-b810-0b6fbc40f437?alt=media&token=27df9721-84fa-4d1e-a531-ce047cc40733",
  projectId : "antalya",
  projectName: "M3M Antalya Hills - Google Ads",
  title: "Buy 2.5 & 3.5 BHK Low Rise Floors at M3M Antalya Hills in Sector 79, Gurugram. Luxury floors for a luxurious living.",
  description:
    "M3M Antalya Hills in Sector 79, Gurgaon, epitomizes luxury living, brought to life by M3M India, a reputable real estate developer with over a decade of experience. Located in a prime area of Gurgaon, this upscale project offers an array of 2.5 BHK and 3.5 BHK Hill Living Apartments, each thoughtfully crafted to blend style and comfort seamlessly. With excellent connectivity to the Indira Gandhi International Airport and major roadways, M3M Antalya Hills is an ideal choice for discerning homeowners.",
  favicon:
    "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FM3M.png3f493088-2237-4225-b641-8d0c3c5be3b3?alt=media&token=b7b63d81-b138-4f87-aad9-e6d89b13217c",
  heroSection: {
    mobileImages: [
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FM3M%20ANTALYA%20mobile%20hero%20image%201.webp9684f47b-c8b9-4b9c-a3d6-18cb2cf2234d?alt=media&token=7a945669-bfdb-4a11-8c74-6da052f61758",
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fantalya%20mobile%20web.webp4d9cb48c-f9e6-4fee-b137-a438ec9121bd?alt=media&token=77f2aa3a-efcf-4f54-a0ad-260b9d703404",
    ],
    desktopImages: [
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FM3M%20desktop%20hero%20image%201.webp4ea0019e-65a2-4a71-ac12-c29c34fb7016?alt=media&token=863fcc75-ad29-47e8-ba90-c7a6bd85ea73",
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FM3M%20ANTALYA%20desktop%20hero%20image%202.webpb3b775f8-7b5a-4de0-b3d1-166ba702e22f?alt=media&token=ffb22104-34f0-43fe-9166-a0a45c3559df",
    ],
    heading: "Overview",
    addressHeading: "M3M Antalya Hills",
    addressSubHeading: "At Sector 79, Gurugram By M3M India",
    description:
      "M3M Antalya Hills in Sector 79, Gurgaon, epitomizes luxury living, brought to life by M3M India, a reputable real estate developer with over a decade of experience. Located in a prime area of Gurgaon, this upscale project offers an array of 2.5 BHK and 3.5 BHK Hill Living Apartments, each thoughtfully crafted to blend style and comfort seamlessly. With excellent connectivity to the Indira Gandhi International Airport and major roadways, M3M Antalya Hills is an ideal choice for discerning homeowners. </br></br> The apartments stand out for their cutting-edge design and modern aesthetics, making them a lifestyle statement rather than just a residence. Prioritizing both luxury and well-being, the project offers a healthy living environment alongside world-class amenities.",
    button: "Download Brochure",
    popupData: {
      heading: "Download Brochure ",
      button: "Download Brochure",
    },
  },
  //card data
  cardData: {
    popupData: {
      heading: "Enquiry ",
      button: "Enquiry",
    },
    heading: "New Launch",
    mainHeading: "M3M Antalya Hills",
    address: "At Sector 79, Gurugram",
    subAddress: "By M3M India",
    fields: [
      { key: "Low Rise Floors", label: "Type" },
      { key: "2027", label: "Possession" },
      { key: "S+4", label: "Floors" },
    ],
    buttonData: {
      discount: "Newly launched!",
      plantTime: "Luxury 2.5 & 3.5 BHKs",
      edition: "Special payment plan!",
    },
    description: "Express your interest today!",
    price: "1.67 Cr Onwards",
    
    rera: "RERA No. : RC/REP/HARERA/GGM/833/565/2024/60",
  },
  //Price
  price: {
    heading: "Tentative Price",
    tableHeading: ["Type", "Area", "Price"],
    plans: [
      {
        type: "2.5 BHK",
        sba: "1159 sq ft (approx.)",
        price: "Rs. 1.67 Cr",
        button: "Request For Details",
      },
      {
        type: "3.5 BHK",
        sba: "1547 sq ft (approx.)",
        price: "On request",
        button: "Request For Details",
      },
    ],
  },
  //Site & Floor Plan
  siteAndFloorPlan: {
    heading: "Site & Floor Plan",
    popupData: {
      heading: "Request for plan details",
      button: "Request for plan details ",
    },
    floorPlans: [
      {
        heading: "Master Plan Plan",
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FAntalya%20masterplan.webp4629113d-094a-4f7e-9413-e58fb0cb34ff?alt=media&token=cea79a9e-d3e4-4513-b37f-3ab3d23ee6ca",
        button: "Master Plan",
        hoverButton: "Enquire Now",
      },
      {
        heading: "Floor Plan",
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fantalya%202.5%20bhk.webp1b9ebbbb-7f9b-483b-8ac8-44d11fcff22a?alt=media&token=f6aab2c1-7584-48c1-9d04-b264fa2c969b",
        button: "2.5 BHK",
        hoverButton: "Enquire Now",
      },
      {
        heading: "Floor Plan",
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fantalya%203.5%20bhk.webp69088835-aa3f-4dc3-98d0-da7ae69bc2a4?alt=media&token=bf56c33b-bc89-4280-9d16-509873840c95",
        button: "3.5 BHK",
        hoverButton: "Enquire Now",
      },
    ],
  },
  //AMENITIES
  amenities: {
    heading: "Amenities",
    image: false,
    images: [
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Famp.svgcdd31981-cadc-466e-b4ca-39b0646e70f0?alt=media&token=07c20efe-e5e5-4517-b133-26ee07a641c3",
        bottomSideHeading: "AMPHITHEATRE",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fbasketball-court.svg6a0442ac-3a47-492e-aa67-b33fd7672a47?alt=media&token=a3fcfd38-5006-4686-aeec-41ed7ad3f11a",
        bottomSideHeading: "BASKETBALL COURT",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fcricket.svgdc6f93ec-beec-4839-a7c3-e028d5ef5f00?alt=media&token=9891bd72-9d16-4d73-8774-13bd68b29639",
        bottomSideHeading: "CRICKET PRACTICE NET",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftable-tennis.svgcae56e9d-c1b2-43a8-8b36-62f46a0162a8?alt=media&token=8aeaa039-24fc-43af-90eb-a0e1145a3396",
        bottomSideHeading: "TABLE TENNIS",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fjogging.svg9aa328dd-c386-4bd4-9751-b5d87f9a8006?alt=media&token=10dcca65-1c34-4152-8ddb-a474574cb92a",
        bottomSideHeading: "JOGGING TRACK",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fgym.svga6462c92-2def-4067-9c45-c9013ee32c2b?alt=media&token=39cf2c2f-edb0-4aff-bf8d-bb9c63058dd3",
        bottomSideHeading: "GYM",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Freflexology.svg272a3d7e-481c-4f3e-a0a3-8f8ddfe88bfb?alt=media&token=e36ab038-8c51-4836-bee0-c1acfb379f01",
        bottomSideHeading: "REFLEXOLOGY PATHWAY",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fswm.svg0936427f-6d8c-44a1-b5ad-e172b28b65c4?alt=media&token=ccb6d048-d265-4c33-bd26-56b3f1ac1edb",
        bottomSideHeading: "SWIMMING POOL",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fkids.svge0ffa078-52ac-44ec-ae4e-1b843e328fe8?alt=media&token=1ffe893d-8644-4c14-b94b-bd531d98b773",
        bottomSideHeading: "KIDS PLAY AREA",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftrack-bicycle.svgb2654b89-e1be-4fdd-ade2-54cbbcf12175?alt=media&token=194a5e91-96c1-471d-8d8d-0c06dc9ad0db",
        bottomSideHeading: "CYCLING TRACK",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fyoga.svg220df747-446e-49da-afd7-eb796a43bee5?alt=media&token=8239ab9b-75a4-4f89-bb74-4dc49aaf6ceb",
        bottomSideHeading: "YOGA LAWN",
      },
    ],
    icons: [
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Famp.svgcdd31981-cadc-466e-b4ca-39b0646e70f0?alt=media&token=07c20efe-e5e5-4517-b133-26ee07a641c3",
        bottomSideHeading: "AMPHITHEATRE",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fbasketball-court.svg6a0442ac-3a47-492e-aa67-b33fd7672a47?alt=media&token=a3fcfd38-5006-4686-aeec-41ed7ad3f11a",
        bottomSideHeading: "BASKETBALL COURT",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fcricket.svgdc6f93ec-beec-4839-a7c3-e028d5ef5f00?alt=media&token=9891bd72-9d16-4d73-8774-13bd68b29639",
        bottomSideHeading: "CRICKET PRACTICE NET",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftable-tennis.svgcae56e9d-c1b2-43a8-8b36-62f46a0162a8?alt=media&token=8aeaa039-24fc-43af-90eb-a0e1145a3396",
        bottomSideHeading: "TABLE TENNIS",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fjogging.svg9aa328dd-c386-4bd4-9751-b5d87f9a8006?alt=media&token=10dcca65-1c34-4152-8ddb-a474574cb92a",
        bottomSideHeading: "JOGGING TRACK",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fgym.svga6462c92-2def-4067-9c45-c9013ee32c2b?alt=media&token=39cf2c2f-edb0-4aff-bf8d-bb9c63058dd3",
        bottomSideHeading: "GYM",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Freflexology.svg272a3d7e-481c-4f3e-a0a3-8f8ddfe88bfb?alt=media&token=e36ab038-8c51-4836-bee0-c1acfb379f01",
        bottomSideHeading: "REFLEXOLOGY PATHWAY",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fswm.svg0936427f-6d8c-44a1-b5ad-e172b28b65c4?alt=media&token=ccb6d048-d265-4c33-bd26-56b3f1ac1edb",
        bottomSideHeading: "SWIMMING POOL",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fkids.svge0ffa078-52ac-44ec-ae4e-1b843e328fe8?alt=media&token=1ffe893d-8644-4c14-b94b-bd531d98b773",
        bottomSideHeading: "KIDS PLAY AREA",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Ftrack-bicycle.svgb2654b89-e1be-4fdd-ade2-54cbbcf12175?alt=media&token=194a5e91-96c1-471d-8d8d-0c06dc9ad0db",
        bottomSideHeading: "CYCLING TRACK",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fyoga.svg220df747-446e-49da-afd7-eb796a43bee5?alt=media&token=8239ab9b-75a4-4f89-bb74-4dc49aaf6ceb",
        bottomSideHeading: "YOGA LAWN",
      },
    ],
  },
  //Gallery
  gallery: {
    heading: "Gallery",
    images: [
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fm3m%20gallery%201.webp2fb89743-4a80-40a6-8396-b58f2e58116d?alt=media&token=ea3cd4b3-c737-4f00-9fdc-786e1bf5c156",
        bottomSideHeading: "Artistic Impression",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fm3m%20gallery%202.webpe2e931a1-1cc5-4cdb-b432-a5b35e92d3a3?alt=media&token=b3abb59e-f069-453a-ac44-cc1b4d60f0e5",
        bottomSideHeading: "Artistic Impression",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fm3m%20gallery%203.webp89ba6a56-d4e0-4344-9047-8a3b83e7cceb?alt=media&token=6439603f-a85d-48b9-92ad-c006f6742866",
        bottomSideHeading: "Artistic Impression",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fm3m%20gallery%204.webp10feeffc-dab2-416a-a6b1-b65f3d17b3ff?alt=media&token=9a4d0a36-f841-40a4-a155-deead4a47988",
        bottomSideHeading: "Artistic Impression",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fm3m%20gallery%205.webpcd12c7ab-7edf-40db-a36c-cc66a2fca210?alt=media&token=89e73148-ec65-4ce4-9fcd-1e28439d3ed2",
        bottomSideHeading: "Artistic Impression",
      },
      {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fm3m%20gallery%206.webp9e964da1-29e5-4660-8f6c-77bf3928be93?alt=media&token=e1a1bfa9-0d59-48bb-a89e-0f7af89c77cb",
        bottomSideHeading: "Artistic Impression",
      },
       {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FM3M%20ANTALYA%20gallery%207.webp560afcf5-85f4-4a8f-9e7c-06af41c52eb6?alt=media&token=05d81311-5af4-4f71-8c81-ab4c1ac2bd3e",
        bottomSideHeading: "Artistic Impression",
      },
       {
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FM3M%20ANTALYA%20gallery%208.webp70c3b4ee-1694-4461-850b-1d40fb45a621?alt=media&token=24c41cfe-fa08-4ba6-942f-dc020cc6c18e",
        bottomSideHeading: "Artistic Impression",
      },
    ],
  },

  //LOCATION
  location: {
    heading: "Location",
    mapView: {
      heading: "Map View",
      mapLink:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2770.4390348038396!2d76.97008157438745!3d28.35729407581616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3d08fd4687b7%3A0x74bc7b179b80852!2sM3M%20Antalya%20Hills!5e1!3m2!1sen!2sin!4v1722944651003!5m2!1sen!2sin",
      button: "Know More About Location",
      popupData: {
        heading: "Explore Location",
        button: "Request Location",
      },
    },
    locationMap: {
      heading: "Location Map",
      imageLink:
        "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2Fantalya%20location%20map.webp32ee02fe-10ed-4a74-b897-e4fc478c0730?alt=media&token=8c7909b0-8d72-41a7-a866-bbc89e7640b6",
      button: "View Location Map",
      popupData: {
        heading: "Explore Location",
        button: "Request Location",
      },
    },
  },
  //Virutal  Taur
  virtualSiteTour: {
    heading: "Virtual Tour",
    items: [
      {
        heading: "VIRTUAL SITE TOUR",
        subHeading: "M3M Antalya Hills at Sector 79, Gurugram",
        bottomSideHeading: "Artistic Impression",
        topSideHeading: "",
        backgroundImage:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FM3M%20ANTALYA%20VIRTUAL%20TOUR.webp6d006cb1-079d-4a71-99d4-200da66f8ea3?alt=media&token=60041030-ce8b-428f-84ad-5894a295f445",
        popupData: {
          heading: "Request For Virtual Site Tour",
          button: "Virtual Site Tour",
        },
      },
      {
        heading: "SAMPLE FLAT",
        subHeading: "M3M Antalya Hills at Sector 79, Gurugram",
        bottomSideHeading: "Artistic Impression",
        // topSideHeading: "Unleash Your Golfing Skills on Breathtaking 9-Hole Chip & Golf Greens",
        backgroundImage:
          "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FANTALYA%20HILLS%20sample%20apt.webpbc3b080e-c72b-4745-89d0-a25b9150ee43?alt=media&token=41655d81-88ed-40e6-b51e-14719777536e",
        popupData: {
          heading: "Request For Virtual Site Tour",
          button: "Virtual Site Tour",
        },
      },
    ],
  },
  //about the developer
  aboutthedeveloper: {
    heading: "About The Developer",
    para: "M3M India is a leading real estate developer renowned for its innovative and luxurious projects across India. With a strong presence in Gurgaon, M3M India is known for creating high-end residential and commercial spaces that blend modern design with premium amenities. Over the years, the company has built a reputation for excellence, delivering projects that prioritize quality, comfort, and timely execution. M3M India’s portfolio includes a diverse range of developments, from upscale apartments and villas to commercial complexes, reflecting its commitment to redefining urban living and enhancing the real estate landscape.",
  },
  footer: {
    topLine: "Channel Partner RERA No. : RC/REA/HARERA/GGM/1327/922/2O2O/26",
    bottomLine: "Project RERA No. : RC/REP/HARERA/GGM/833/565/2024/60",
    link: "https://haryanarera.gov.in/",
    disclimer:
      "This is not the official website of the developer & property, it belongs to an authorised channel partner for information purposes only. All rights for logos & images are reserved to the developer. Thank you for visiting our website. This disclaimer Disclaimer is applicable to this website and all microsites and websites owned by us. By using or accessing this website you agree with the Disclaimer without any qualification or limitation. This website is in the process of being updated. By accessing this website, the viewer confirms that the information including brochures and marketing collaterals on this website are solely for informational purposes only and the viewer has not relied on this information for making any booking/purchase in any project of the company. Nothing on this website, constitutes advertising, marketing, booking, selling or an offer for sale, or invitation to purchase a unit in any project by the company. The company is not liable for any consequence of any action taken by the viewer relying on such material/information on this website.",
    footerAllRight:
      "All Rights Reserved. © 2024 Copyright PropCrown Global LLP",
  },
  details: {
    whatsappNumber: "+919990887599",
    phoneNumber: "+919990887599",
    whatsappMessage:
      "Hi, I am interested in M3M Antalya Hills. Please share the details with me.",
  },
  formData: {
    placeHolder: "Please suggest a time (in IST) to get in touch.",
    phonePlaceholder : "Phone number with country code",
    popup: {
      rows: 3,
    },
    sideFormRows: 2,
  },
  //thank you form
  thankYouPage: {
    heading: "Thank You",
    thankyouText: "for filling the form",
    image:
      "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FM3M%20ANTALYA%20desktop%20hero%20image%202.webpb3b775f8-7b5a-4de0-b3d1-166ba702e22f?alt=media&token=ffb22104-34f0-43fe-9166-a0a45c3559df",
    appreciateLines: {
      lineOne: "We appreciate your interest",
      lineTwo: "and will get back to you shortly.",
    },
  },
  disclaimer: `
    <section>
      <h2>Disclaimer</h2>
      <p>
        This website is only for the purpose of providing information regarding real estate projects in different regions. By accessing this website, the viewer confirms that the information including brochures and marketing collaterals on this website is solely for informational purposes and the viewer has not relied on this information for making any booking/purchase in any project of the company. Nothing on this website constitutes advertising, marketing, booking, selling or an offer for sale, or invitation to purchase a unit in any project by the company. The company is not liable for any consequence of any action taken by the viewer relying on such material/ information on this website.
      </p>
      <p>
        Please also note that the company has not verified the information and the compliances of the projects. Further, the company has not checked the RERA (Real Estate Regulation Act 2016) registration status of the real estate projects listed herein. The company does not make any representation in regards to the compliances done against these projects. You should make yourself aware of the RERA registration status of the listed real estate projects before purchasing property.
      </p>
      <p>
        This site is for information purposes only and should not be treated as the official website.
      </p>
    </section>
  
    <section>
      <h2>Privacy Policy</h2>
      <h3>Updation of privacy policy</h3>
      <p>
        This privacy policy is subject to undergo change and review without any prior notice or approval. So to keep yourself updated on the changes introduced, please keep visiting and reviewing the terms and conditions of this privacy policy.
      </p>
  
      <h3>User information</h3>
      <p>
        By using our website, you agree to abide by the rules laid out by us and consent to the collection and use of all such information that you may furnish to, or through, our website. In some cases, while you visit our website, you may not need to provide any personal information. But in certain instances, we must have your personal information in order for us to grant you access to some of the links or sites. Such links/ pages may ask for your name, e-mail address, phone number, etc. The information furnished by you is used to provide relevant products and services and to acknowledge receipt of your communication or to send out information and updates to you. You have the option of requesting removal from our mailing list. We do not give away your personal information to any third party.
      </p>
  
      <h3>Security</h3>
      <p>
        To ensure security while transferring sensitive information, all the ongoing transmissions between client and server are encrypted using advanced and standard protocols. We also practice restricted access by employees and hold them to high levels of confidentiality.
      </p>
  
      <h3>Use of cookies</h3>
      <p>
        We may use cookies for security, session continuity, and customization purposes. In case of a user opting to reject a cookie, he/ she may not be able to gain access to some of the limited services or use some features of the site.
      </p>
  
      <p>
        In case of any queries or suggestions regarding the privacy statement or your dealings with this website, please contact:
      </p>
    </section>
  `,
  popup: {
    leftSide: {
      heading: "We Promise",
      iconsImages: [
        {
          label: "Instant Call Back",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/imageuploader-7809e.appspot.com/o/images%2Fcall-back-icon-16.jpgfeb2b5f4-9b58-4eae-87e9-c8aca81d5e45?alt=media&token=bb54e66f-5637-4ca0-9f40-2008ee8b2802",
        },
        {
          label: "Project Video Conferencing",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/image-uploader-links.appspot.com/o/images%2FVC.svgcb712f12-aac9-492a-a2f7-74c63a3918ea?alt=media&token=3848f904-807c-4e6c-9751-832759017e10",
        },
        {
          label: "Unmatched Price",
          imageLink:
            "https://firebasestorage.googleapis.com/v0/b/imageuploader-7809e.appspot.com/o/images%2Fbest-deal-badge-icon-best-deal-banners-badge-sticker-sign-tag-best-offer-modern-style-illustration-vector.jpg2c5a7144-c7c9-4913-b229-5280ed00fdc4?alt=media&token=ddf9f9c3-d403-4961-9557-850792a7b112",
        },
      ],
    },
    rightSide: {
      heading: "Project Highlights",
      points: [
        "Book Unit Now",
        "Excellent Connectivity",
        "Luxurious Living",
        "Preference for NRIs",
      ],
    },
  },
  sideForm: {
    topBarLeft: {
      label: "Organise Site Visit",
      popupData: {
        heading: "Request For Virtual Site Tour",
        button: "Virtual Site Tour",
      },
      topBarRight: {
        label: "Whatsapp",
        imageLink: "",
      },
    },
    buttonAfterCallButton: {
      label: "Request for Call Back",
      popupData: {
        heading: "Request For Call Back",
        button: "Request For Call Now ",
      },
    },
  },
  backendUrl: "https://landing-pages-backend.onrender.com",
};
  
  //image ratio
  
  // hero = >
  //   desktop : 1183*730
  //   mobile : 390*400
  //logo =>92*54
  //price => 420*227
  // floorPlan => 425*319
  //amenites => square
  //gallery => square
  //location =>687*310
  //video => 569*323
