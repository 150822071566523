import React from 'react'
import './Amenities.css'
import Heading from '../../components/Heading/Heading'
import IconCarousel from './IconCarousel'
const Amenities = ({ data }) => {
    const { amenities } = data;
  return (
      <div>
          <div
              className='container'

          >
              
              <div
                  className='amenitiesHeading'
              
              >
                 
              <Heading
                  text={
                      amenities?.heading
                  }
                  color = {"black"}
                  />
              </div>
             
                      
                      <IconCarousel
                          icons={
                              amenities?.icons
                          }

                      />
              



          </div>
    </div>
  )
}

export default Amenities