
import ReactGA from "react-ga";

export const initializeAnalytics = (trackingId) => {
  ReactGA.initialize(trackingId);
};

export const logPageView = (trackingId, path) => {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
};
