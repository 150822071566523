import React from 'react'


const Price = ({ data } ) => {
    const { price } = data;
  return (
      <div
          style={{
          width :"100%",
              backgroundColor: "black",
              color: "white"
              , display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding :" 20px 0"
          }}
      
      >
          <table style={{
              borderCollapse: 'collapse',
              

           }}>
              <thead>
                  <tr>
                      {
                          price?.tableHeading?.map((item) => {
                              return (<th style={{ padding: '8px' }}>

                                  {item}
                              </th>)
                          })
                      }

                  </tr>
              </thead>
              <tbody
                  style={{
                      
                  }}

              >
                  {price?.plans?.map((item) => (
                      <tr key={item.type}>
                          <td style={{textAlign: "center" }}>{item?.type}</td>
                          <td style={{  padding: '8px', textAlign: "center" }}>{item.sba}</td>
                          <td style={{
                            
                              padding: '8px',

                          }}>
                              <div
                                  style={{
                                      justifyContent: "space-between",
                                      display: "flex",
                                      alignItems: 'center',

                                      height: '100%',
                                      width: "100%"
                                  }}
                              >
                                  <div style={{
                                      width: 'auto',
                                      minWidth: "80px",
                                      justifyContent: "space-between",
                                      display: "flex",
                                      alignItems: "center",
                                      textAlign: "center",

                                      flex: 1, paddingLeft: "10px"


                                  }}>
                                      {item?.price}
                                  </div>
                                 
                              </div>
                          </td>

                      </tr>
                  ))}
              </tbody>
          </table> :
    </div>
  )
}

export default Price