import { message as antdMessage } from 'antd';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { apiCalling } from '../../../config/apiCalling';

import './EntryForm.css';

const EntryForm = ({ setIsThankYouPage, dialCode, heading, data }) => {
    console.log('heading', heading);
    const { frontHighLightedCard} =  data;
    const [projectId, setProjectId] = useState()
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [countryCode, setCountryCode] = useState(dialCode || 'in');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');



    function toLowercaseJoined() {
        const sentence = heading + data?.projectId
        const words = sentence.split(' ');
        console.log(words.join('').toLowerCase())
        setProjectId(words.join('').toLowerCase())
    }
    useEffect(() => {
        toLowercaseJoined()
    }, [heading])


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name) {
            antdMessage.error('Please enter your name.');
            return;
        }
        if (!email) {
            antdMessage.error('Please enter your email.');
            return;
        }
        if (!number) {
            antdMessage.error('Please enter your phone number.');
            return;
        }


        const formData = {
            name,
            email,
            phone: number,
            message,
            reason: heading
        };

        setLoading(true);
        const dataa = await apiCalling(formData,data);
        if (dataa === 'success') {
            setIsThankYouPage(true);
        } else {
            antdMessage.error("Something went wrong, please try again later.");
        }
        setLoading(false);
    };

    const handlePhoneInputChange = (value, data) => {
        setNumber(value);
        setCountryCode(data.dialCode);
    };
    

    const formData = frontHighLightedCard?.getInTouch?.formData;

    return (
        <div  className="entry-form">
            <form id={projectId}       onSubmit={handleSubmit}>
                <input
                    placeholder={formData?.labelOne}
                    style = {{fontSize: '16px'}}
                    className='inputForm inputFormName'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    placeholder={formData?.labelTwo}
                    className='inputForm'
                    style = {{fontSize: '16px'}}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    placeholder={formData?.labelFour}
                    className='inputForm'
                    style = {{fontSize: '16px'}}
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                />
               
                <textarea
                    placeholder={formData?.labelFive}
                    className='inputForm'
                    value={message}
                    style = {{fontSize: '16px'}}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={formData?.rows}
                />
                <div className='submitButtonDiv'>
                    <button className='submitButton' type="submit" disabled={loading}>
                        {loading ? 'Submitting...' : formData?.button || 'Submit'}
                    </button>
                    <p>{data?.frontHighLightedCard?.highlightingNumber}</p>
                </div>
            </form>
        </div>
    );
};

export default EntryForm;
