
import React from 'react'
import DOMPurify from 'dompurify';
import Logo from "../../component/Logo/Logo";
const AboutTheDeveloper = ({data}) => {
    const {aboutthedeveloper} = data
    const sanitizedDescription = DOMPurify.sanitize(aboutthedeveloper.para || '');
  return (
     

          <div
           
              className="container"
      >
          <div>
              <Logo data={data} />
          </div>
     
            
              <h3
                  style={{
                      margin: "15px 0",
                      color: "rgba(2,43,45)",
                  }}
              >
              {aboutthedeveloper?.heading}
              </h3>
              <div
                  style={{
                      lineHeight: "25px",
                  }}
                  dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
              />

          </div>
  )
}

export default AboutTheDeveloper