import {  Col } from 'antd'
import React from 'react'
import './ImagesAndOffer.css'
import HighlightingCard from '../../components/HighlightingCard/HighlightingCard'

const ImagesAndOffer = ({data}) => {
    const {imageAndOffer} =data
  return (
    <div className='imageAndOffer'>
      <Col className='columnImageAndOffer ' xs={16} md={8} xl={4} lg={6}  >
      <img  className='imageAndOfferImage'
        src={imageAndOffer?.imageLink} />
        </Col>
      {
        imageAndOffer?.cards?.map((item, key) => (
          <Col  xs={24} md={11} xl={6} lg={8}  >
            <HighlightingCard data ={data} card={item} />
          </Col>
            ))
          
      }

    </div>
  )
}

export default ImagesAndOffer