import React, { useEffect, useState } from "react"; 
import Loader from "./components/component/Loader/Loader"; 
import Thankyoupage from "./components/component/ThankYouPage/Thankyoupage"; 
import LeftSide from "./components/Sides/LeftSide/LeftSide"; 
import SideForm from "./components/Sides/RightSide/SideForm"; 
import { Route, Routes, useLocation, useParams } from "react-router-dom"; 
import Disclaimer from "./components/component/Disclaimer/Disclaimer"; 
import GATracking from "../GATracking";
import './HomePage.css' 
import { Helmet } from "react-helmet";
import { initializeAnalytics, logPageView } from "../config/googleAnalytics";

const HomePage = React.memo(({ imagesLoading, dialCode, data,
    setDialCode,
    setImagesLoading


 }) => { 
    const params = useParams(); 
    const [isSmallScreen, setIsSmallScreen] = useState(false); 
    const { "*": path } = params; 
    const ending = path.endsWith("/disclaimer") 
    const [showThankYouPage, setShowThankYouPage] = useState(false); 

    const location = useLocation();
    useEffect(() => {
        initializeAnalytics(data?.googleTrackingId); 
        logPageView(data?.googleTrackingId, location.pathname + location.search);
    }, [location]);

    useEffect(() => { 
        const handleResize = () => { 
            setIsSmallScreen(window.innerWidth <= 1150); 
        }; 
        window.addEventListener("resize", handleResize); 
        handleResize(); 
        return () => window.removeEventListener("resize", handleResize); 
    }, []); 

    return ( 
        <div id="valancia" style={{ display: "flex" }}> 
               <GATracking trackingId={data?.trackingId}/>
            
            <Helmet>
                <title>{data?.title}</title>
                <meta name="description" content={data?.description} />
                <link rel="icon" type="image/png" href={data?.favicon} sizes="16x16" />
            </Helmet>
            {ending || path  === 'disclaimer' 
                ? 
            <Routes> 
                <Route path={`/disclaimer`}  element={<Disclaimer data = {data} />} /> 
            </Routes> 
: 
            <> 
            {imagesLoading ? ( 
                <Loader data={data} /> 
            ) : ( 
                <> 
                    {showThankYouPage ? ( 
                            <Thankyoupage data={data} setIsThankYouPage={setShowThankYouPage} /> 
                    ) : ( 
                        <> 
                            <div 
                                style={isSmallScreen ? { width: "100vw" } : { width: "77vw" }} 
                            > 
                                        <LeftSide 
                                            data={data}  
                                    dialCode={dialCode} 
                                    setIsThankYouPage={setShowThankYouPage} 
                                /> 
                            </div> 
                            {!isSmallScreen && ( 
                                <div 
                                    style={{ 

                                        width: "23vw", 

                                        position: "sticky", 

                                        height: "100vh", 

                                        backgroundColor: "white", 

                                        top: "0", 

                                    }} 

                                > 

                                            <SideForm 
                                                data={data}  
                                        setIsThankYouPage={setShowThankYouPage} 
                                        dialCode={dialCode} 
                                    /> 
                                </div> 
                            )} 
                        </> 
                    )}
                </> 
                )} 
            </>} 
        </div> 
    );
}); 
export default HomePage 
