import React from 'react'
import Heading from '../../components/Heading/Heading';
import './Projects.css'
import { Card } from '../../components/Card/Card';
import { Col } from 'antd';

const Projects = ({ setIsThankYouPage, dialCode, data }) => {
    const { ourProjects } = data;
    

  return (
      <div className='projects'>
          <div className='mainHeading'>
           
              <Heading text={ourProjects.heading} />
              
          </div>
          <div className='cardSections'>
              <div className='hiddenMobileBackground'>
                  
              </div>

              <Col xs={24} lg ={18}  className='cards'>
                  <div
                      style={{
                    
                          width: '100%',
                          display: 'flex',
                          flexWrap :"wrap",
                          justifyContent: 'center',
                          alignItems: "center",
                          columnGap : "2"
                      }}
                  >
                    
                  {ourProjects?.cards.map((card, index) => (<Card
                      setIsThankYouPage={setIsThankYouPage}
                      dialCode={dialCode}
                      data ={data}
                      card={card} />))}
                  </div>
              </Col>
          
          </div>
          
    </div>
  )
}

export default Projects