import React from 'react';
import './CallingFooter.css';
import { FaPhoneAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";

const CallingFooter = ({ data }) => {
    const { details } = data;
    const handleCallClick = () => {
        const callNumber = details?.phoneNumber; 
        window.location.href = `tel:${callNumber}`;
    };

    const handleWhatsAppClick = () => {
        const whatsappMessage = details?.whatsappMessage || 'Hello!';
        const whatsappNumber =details?.whatsappNumber || '+0987654321'; // Replace with the WhatsApp number
        const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;
        window.open(url, '_blank');
    };

    return (
        <div className='footerCalling'>
            <button
                style={{
                    width: '50px',
                    height: '50px',
                    background: "green",
                    border: "none",
                    borderRadius: "50%",
                    padding: "0px",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems : "center"
                    
                }}
                
                onClick={handleCallClick}>

                <FaPhoneAlt
                    style={{
                        color: "white",
                       
                    }}
                    size={28} /> 

            </button>
            <button
                style={{
                    width: '50px',
                    height: '50px',
                    background: "green",
                    border: "none",
                    borderRadius: "50%",
                    padding: "0px",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center"

                }}
                
                onClick={handleWhatsAppClick}>

                <FaWhatsapp
                
                    style={{
                        color: "white",

                    }}
                    size={28} 
                />
            </button>
        </div>
    );
};

export default CallingFooter;
