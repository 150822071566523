import { Col } from 'antd'

import React from 'react'

import './SideForm.css'

import EntryForm from '../EntryForm/EntryForm';

const SideForm = ({ setIsThankYouPage, dialCode, data }) => {

  const { frontHighLightedCard } = data;

  return (

    <Col xs={24} lg={10} xl={8} className='sideForm'>

      <Col className='sideForm2' />

      <div className='sideFormHeading'>

        <h1>



          {frontHighLightedCard?.heading}

        </h1>

      </div>
      <div className='animatingHighlightingCard'>
        <span><img src={frontHighLightedCard?.animatingHighLight?.imageLink} /></span>
        {frontHighLightedCard?.animatingHighLight?.label}
      </div>
      <div className='sideForm2ndEntry'>
        <div>{frontHighLightedCard?.secondHighlight?.lineOne}</div>
        <p>{frontHighLightedCard?.secondHighlight?.lineTwo}</p>
      </div>
      <div className='sideForm2ndEntry sideForm3rdEntry'>
        <div>
          {frontHighLightedCard?.thirdHighLight?.textOne}
          <b>
            {frontHighLightedCard?.thirdHighLight?.textTwo}
          </b>
          {frontHighLightedCard?.thirdHighLight?.textThree}
        </div>
      </div>
      <div>
        <div className='getInTouchFormHeading'>
          <img src={frontHighLightedCard?.getInTouch?.heading?.imageLink} />
          {frontHighLightedCard?.getInTouch?.heading?.label}
        </div>
      </div>
      <EntryForm
        data={data}
        heading={frontHighLightedCard?.heading}
        setIsThankYouPage={setIsThankYouPage}
        dialCode={dialCode}
      />
    </Col>

  )

}



export default SideForm 
