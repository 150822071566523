import { Col, Modal } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './Card.css'
import PopupForm from '../PopupForm/PopupForm'

export const Card = ({ card, setIsThankYouPage, dialCode, data }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(false)
  const [sendingHeading, setSendingHeading] = useState(false);
  
  return (
    <Col className='card' xs={24} sm = {18} md={8} xl={7} >
      <div className='cardImage'>
        <img src={card?.imageLink} />
        <div className='projectName'>
          {card?.projectName}
        </div>
      </div>
      <div className='projectLocation'>
        {
          card?.locationName
        }
      </div>
      <div className='cardDetails'>
        {
          card?.projectDetails?.map((item, key) => (<div key = {key}>


            <div className='cardDetailsHeading' >
              {item?.label}
            </div>
            <div className='cardDetailsValue' >
              {item?.value}
            </div>

            </div>))
        }
        <div className='checkPoints'>
          <div className='cardDetailsHeading' >
            {card?.homeHeistScheme?.label}
          </div>
          <div>
            {
              card?.homeHeistScheme?.value?.map((item, key) => (<div className='homeHeistSchemeItem'>
                <div className='dot'>

                </div>

                <div className='homeHeistSchemeFlex'>
                
                {item}
                </div>
                

                </div>))
            }
          </div>


        </div>

      </div>
      <div>
        <button 
          onClick={() => {
            window.open(card?.projectUrl, '_blank');
            // navigate(card?.projectUrl)
            // setModal(true)
            // setSendingHeading(card?.projectName)
          }}
      
        className='cardButton'>
          {card?.button}
        </button>
      </div>

      

      <Modal
        open={modal}
        footer={false}
        onCancel={() => setModal(false)}
      >
        <PopupForm
          data={data}
          
          heading={sendingHeading}
          dialCode={dialCode}
          setIsThankYouPage={setIsThankYouPage}
        />
      </Modal>






    </Col>
  )
}
